import {
    Component,
    DestroyRef,
    inject,
    Input,
    OnChanges,
    OnInit,
    Pipe,
    PipeTransform,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import {
    ESignOffStatus,
    FilesRevisionModel,
    PublicationItemFilesModel,
    PublicationItemModel
} from '../../../../../../../models/api/publication-item.model';
import {RLDatePipe} from '../../../../../../../pipes/rl-date.pipe';
import {NullUndefinedPipe} from '../../../../../../../pipes/null-undefined.pipe';
import {TabBarItemModel} from '../../../../../../../models/ui/tab-bar-item.model';
import {DataFieldModel} from '../../../../../../../models/api/data-field.model';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';
import {
    CustomWorkflowActionModel,
    EItemActionName,
    ETransitionTriggerActionName,
    EWorkflowActionName
} from '../../../../../../../models/api/custom-workflow-action.model';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {AppConstants} from '../../../../../../../app.constants';
import {
    FullModalConfig,
    FullModalService,
    GetPropertyPipe,
    NUCButtonBarModule,
    NUCButtonsModule,
    NUCEmptyStateModule,
    NUCInputsModule,
    NUCTabBarModule,
    NUCTooltipModule
} from '@relayter/rubber-duck';
import {combineLatest, Subscription} from 'rxjs';
import {
    StickyNotesDataService
} from '../../custom-workflow-preview/preview-sticky-notes-sidebar/sticky-notes-data.service';
import {CustomWorkflowService} from '../../custom-workflow.service';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {DataFieldsComponentUtil} from '../../../../../../../classes/data-fields-component.util';
import {
    EPackageType
} from '../../custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {
    DownloadPackageComponent,
    IDownloadPackageTypeModalData
} from '../../../../../../../components/download-package/download-package.component';
import {CustomWorkflowPreviewDataService} from '../../custom-workflow-preview/custom-workflow-preview-data.service';
import {EDownloadFileType} from '../../../../../../../components/download-button/download-button.component';
import {EEngineType, TemplateModel} from '../../../../../../../models/api/template.model';
import {
    IWorkflowUploadItemFilesModalData,
    UploadItemFilesModalFormComponent
} from '../../custom-workflow-forms/upload-item-files-form/upload-item-files-modal-form/upload-item-files-modal-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EPublicationItemContext, PublicationsService} from '../../../../../../../api/services/publications.service';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {EWorkflowActionOptionName} from '../../../../../../../models/api/custom-workflow-option.model';
import {Clipboard} from '@angular/cdk/clipboard';
import {SignedUrlService} from '../../../../../../../api/services/signed-url.service';
import {PipesModule} from '../../../../../../../pipes/pipes.module';
import {CommonModule, NgClass, NgForOf, NgIf} from '@angular/common';
import {ComponentsModule} from '../../../../../../../components/components.module';
import {MatTooltip} from '@angular/material/tooltip';
import {EChannel} from '../../../../../../../app.enums';
import {NgxFilesizeModule} from 'ngx-filesize';

interface IAllowedActions {
    uploadItemFilesAction: CustomWorkflowActionModel;
}

enum EDownloadType {
    SOURCE,
    EXPORT
}

interface IActionOptions {
    display: { name: EWorkflowActionOptionName.DISPLAY; value: string[] };
}

@Component({
    standalone: true,
    selector: 'custom-workflow-publication-item-details-component',
    templateUrl: './publication-item-details.component.html',
    imports: [
        NUCButtonsModule,
        NUCInputsModule,
        PipesModule,
        NUCTabBarModule,
        NgClass,
        NUCTooltipModule,
        NUCButtonBarModule,
        NgIf,
        NgForOf,
        ComponentsModule,
        MatTooltip,
        NUCEmptyStateModule,
        CommonModule,
        NgxFilesizeModule
    ],
    styleUrls: ['./publication-item-details.component.scss']
})
export class PublicationItemDetailsComponent implements OnInit, OnChanges {
    public readonly ESignOffStatus = ESignOffStatus;
    public readonly dateFormats = RLDatePipe.dateFormats;
    public readonly DELETED_USER = NullUndefinedPipe.defaultValues.DELETED_USER;
    public readonly TOOLTIPS = AppConstants.STICKY_NOTE_TOOLTIPS;
    public readonly DOWNLOAD_TYPE = EDownloadType;

    @Input() public publicationItems: PublicationItemModel[];
    @Input() public loading = false;
    @Input() public selectedPublicationItemId: string;
    @Input() public dataFields: DataFieldModel[];
    @Input() public isForm: boolean;

    public _selectedPublicationItem: PublicationItemModel;
    public get selectedPublicationItem(): PublicationItemModel {
        return this._selectedPublicationItem;
    }
    public set selectedPublicationItem(item: PublicationItemModel) {
        this._selectedPublicationItem = item;
        if (this.selectedPublicationItem) {
            this.linkedBriefingItem = this.selectedPublicationItem.campaignItems?.at(0);
        }
        this.updateDownloadButtonState();
        this.updateStickyNoteInfo();
    };
    public publication: PublicationModel;
    public campaignId: string;
    public workflowLayoutId: string;
    public stepId: string;
    private actions: CustomWorkflowActionModel[];
    public activeVariant: VariantModel;
    public notShowUploadItemAction: boolean;

    public selectedFilesRevision: FilesRevisionModel;
    public signOffConfigured = false;
    public showLinkedBriefingAction: CustomWorkflowActionModel;
    public editContentBriefingItemAction: CustomWorkflowActionModel;
    public componentActionOptions: IActionOptions;
    public componentActions: IAllowedActions;
    public briefingInfo: { label: string; value: string|number }[] = [];
    public linkedBriefingItem: CampaignItemModel;
    public downloadAction: CustomWorkflowActionModel;

    private destroyRef = inject(DestroyRef);

    // tab bar related
    public tabBarItems: TabBarItemModel[] = [];
    public disabledTabBarItems: TabBarItemModel[] = [];
    private _selectedTab: TabBarItemModel;
    private publicationItemSubscription: Subscription;

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            if (this.publicationItems[index]?._id !== this.selectedPublicationItem?._id) {
                this.getPublicationItem(this.publicationItems[index]?._id);
            }
            this._selectedTab = tab;
            this._selectedTab.index = index;
        }
    }

    constructor(
            private fullModalService: FullModalService,
            private customWorkflowService: CustomWorkflowService,
            private stickyNotesDataService: StickyNotesDataService,
            private previewDataService: CustomWorkflowPreviewDataService,
            private publicationsService: PublicationsService,
            private viewContainerRef: ViewContainerRef,
            private clipboard: Clipboard,
            private signedUrlService: SignedUrlService
    ) {}

    public ngOnInit(): void {
        this.campaignId = this.customWorkflowService.campaign?._id;

        combineLatest([
            this.customWorkflowService.publication$,
            this.customWorkflowService.workflow$,
            this.customWorkflowService.activeStep$
        ]).pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(([publication, workflow, step]) => {
            this.publication = publication;
            this.workflowLayoutId = workflow.layout?._id;
            this.stepId = step._id;
            this.initiateTabBar();
        });

        this.stickyNotesDataService.updatedLinkedCampaignItem$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
            (campaignItem: CampaignItemModel) => {
                this.linkedBriefingItem = campaignItem;
                this.updateStickyNoteInfo();
            }
        );

        this.previewDataService.selectedFilesRevision$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(selectedFilesRevision => this.selectedFilesRevision = selectedFilesRevision);

        combineLatest([this.customWorkflowService.activeVariant$, this.previewDataService.actions$])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([activeVariant, allowedActions]) => {
                this.activeVariant = activeVariant
                this.actions = allowedActions;

                this.updateActions();
                this.updateDownloadButtonState();
                this.updateStickyNoteInfo();
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.publicationItems) {
            if (!changes.publicationItems.firstChange) {
                this.initiateTabBar();
            }

            if (this.selectedPublicationItemId) {
                this.selectedPublicationItem = this.publicationItems.find(item => item._id = this.selectedPublicationItemId);
            }
        }
    }

    private updateActions(): void {
        this.signOffConfigured = !!this.actions.find(action => action.name === EItemActionName.SIGN_OFF);

        this.showLinkedBriefingAction = this.actions.find(action => action.name === EWorkflowActionName.SHOW_LINKED_BRIEFING_ITEM);

        this.componentActionOptions = {
            display: this.showLinkedBriefingAction?.options.find((option) => option.name === EWorkflowActionOptionName.DISPLAY)
        } as IActionOptions;

        this.componentActions = {
            uploadItemFilesAction: !this.notShowUploadItemAction &&
                this.actions?.find((action) => action.name === ETransitionTriggerActionName.UPLOAD_ITEM_FILES && !!action.transition)
        } as IAllowedActions;

        this.editContentBriefingItemAction =
            this.actions.find(action => action.name === EWorkflowActionName.EDIT_CONTENT_BRIEFING_ITEM);
    }

    private initiateTabBar() {
        this.tabBarItems = [];

        let tabBarIndex = 0;
        let selectedTabBarIndex;
        for (const publicationItem of this.publicationItems) {
            const tabBarItem = new TabBarItemModel(publicationItem.getTitle(), tabBarIndex);
            // Disable all other tabs if there is an initial selected publication item id
            if (this.selectedPublicationItemId) {
                if (publicationItem._id !== this.selectedPublicationItemId) {
                    tabBarItem.disabled = true;
                    this.disabledTabBarItems.push(tabBarItem);
                } else {
                    selectedTabBarIndex = tabBarIndex;
                }
                // Don't allow upload files if there is a selected publication item
                this.notShowUploadItemAction = true;
            }
            this.tabBarItems.push(tabBarItem);
            tabBarIndex++;
        }

        if (this.tabBarItems.length > 0) this.selectedTab = this.tabBarItems[selectedTabBarIndex ? selectedTabBarIndex : 0];
    }

    private updateStickyNoteInfo(): void {
        if (this.componentActionOptions?.display) {
            this.briefingInfo = [];
            if (this.linkedBriefingItem) for (const displayProperty of this.componentActionOptions.display.value) {
                const dataField = this.dataFields.find(item => item.fieldName === displayProperty);
                if (dataField) {
                    const value = GetPropertyPipe.transform(this.linkedBriefingItem.dataFields, displayProperty,
                        DataFieldsComponentUtil.getDataFieldFormatter(
                            DataFieldsComponentUtil.getTypeFormatter(dataField.getDataType()),
                            this.activeVariant?.key));
                    this.briefingInfo.push({
                        label: dataField.name,
                        value
                    });
                }
            }
        }
    }

    public goToLinkedBriefingItem(): void {
        this.customWorkflowService.setEditCampaignItemId({
            campaignItemId: this.linkedBriefingItem._id,
            pubItemId: this.selectedPublicationItem._id
        });
    }

    public uploadPublicationItemFiles(): void {
        const requiredUploadCategories = this.componentActions.uploadItemFilesAction.options.find(
            (option) => option.name === EWorkflowActionOptionName.REQUIRED_FILES)?.value;

        const data = {
            publicationItemId: this.selectedPublicationItem._id,
            requiredUploadCategories
        } as IWorkflowUploadItemFilesModalData;

        const config = new FullModalConfig(
            'Add files to publication item',
            'Upload the Export file, Source file and any optional files linked to the source file.',
            data);
        config.viewContainerRef = this.viewContainerRef;
        config.confirmClose = true;

        this.fullModalService.open(UploadItemFilesModalFormComponent, config).afterClosed().pipe().subscribe({
            next: (data) => {
                if (data) {
                    this.customWorkflowService.postTransition(this.componentActions.uploadItemFilesAction.transition, data);
                }
            }
        });
    }

    public downloadPublicationItemFiles(): void {
        const data: IDownloadPackageTypeModalData = {
            actions: [], // can be empty array as the downloadAction is the only action used here
            stepId: this.stepId,
            publicationId: this.publication?._id,
            publicationItemIds: [this.selectedPublicationItem._id],
            variantId: this.activeVariant?._id,
            workflowLayoutId: this.workflowLayoutId,
            downloadAction: this.downloadAction
        };

        // Allow only to download source file and links for items without a template
        if (!this.selectedPublicationItem?.template) {
            data.allowedPackageTypes = [EPackageType.SOURCE, EPackageType.LINKS_PACKAGE];
        }

        const config = new FullModalConfig(
            'Download publication item',
            'Choose the package type',
            data);

        this.fullModalService.open(DownloadPackageComponent, config);
    }

    private updateDownloadButtonState(): void {
        this.downloadAction = this.actions?.find((action) => action.name === EItemActionName.DOWNLOAD &&
            !!this.selectedPublicationItem?.files.find(files => files.variant === this.activeVariant?._id));
    }

    public async onDownloadFile(type: EDownloadType): Promise<void> {
        const fileLink = this.getFilePath(type);
        const validatedUrl = await this.signedUrlService.validateAndRefreshSignedUrl(fileLink)
        if (validatedUrl) window.open(validatedUrl);
    }

    private getFilePath(type: EDownloadType): string {
        let fileLink: string;

        switch (type) {
            case EDownloadType.SOURCE:
                fileLink = this.selectedFilesRevision.files.source.url;
                break;
            case EDownloadType.EXPORT:
                fileLink = this.selectedFilesRevision.files.export.url;
                break;
            default:
                throw new Error('Unknown download type');
        }
        return fileLink;
    }

    public getPublicationItem(itemId: string): void {
        this.publicationItemSubscription?.unsubscribe();

        this.selectedPublicationItem = null;
        this.publicationItemSubscription =
            this.publicationsService.getPublicationItem(this.publication._id, itemId, EPublicationItemContext.SIDEPANEL)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (item) => this.selectedPublicationItem = item,
                    error: Toaster.handleApiError
                });
    }

    public copyToClipboard(): void {
        this.clipboard.copy(this.selectedPublicationItem.getVariantLink(this.activeVariant?._id).url) ? Toaster.success('Copied to clipboard!') :
            Toaster.error('Could not copy to clipboard');
    }

}

@Pipe({name: 'getFileName'})
export class GetFileNamePipe implements PipeTransform {
    public transform(value: PublicationItemFilesModel, type: EDownloadType): any {
        let filePath: string;

        switch (type) {
            case EDownloadType.SOURCE:
                filePath = value.source.url;
                break;
            case EDownloadType.EXPORT:
                filePath = value.export.url;
                break;
            default:
                throw new Error('Unknown download type');
        }
        return new URL(filePath).searchParams.get('response-content-disposition').split('filename=')[1].split(';')[0];
    }

}

@Pipe({name: 'getDownloadIcon'})
export class GetDownloadIcon implements PipeTransform {
    public transform(template: TemplateModel | undefined, type: EDownloadType): EDownloadFileType {
        if (!template) return EDownloadFileType.IMAGE; // for assets return image
        if (type === EDownloadType.SOURCE) {
            switch (template.engineType) {
                case EEngineType.SVG:
                    return EDownloadFileType.IMAGE;
                case EEngineType.INDESIGN:
                    return EDownloadFileType.INDD;
                case EEngineType.AFTER_EFFECTS:
                    return EDownloadFileType.AFTER_EFFECTS;
                default:
                    return EDownloadFileType.IMAGE;
            }
        } else {
            switch (template.engineType) {
                case EEngineType.SVG:
                case EEngineType.INDESIGN:
                    return template.channel === EChannel.PRINT ? EDownloadFileType.PDF : EDownloadFileType.IMAGE;
                case EEngineType.AFTER_EFFECTS:
                    return EDownloadFileType.VIDEO;
                default:
                    return EDownloadFileType.IMAGE;
            }
        }
    }
}
