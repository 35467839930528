<nuc-table emptyStateTitle="You have to create assets first to link it to the product"
           [items]="items"
           [loading]="!assetsSubscription?.closed"
           [columns]="tableColumns"
           (actionClicked)="handleTableRowAction($event)"
           [actions]="actions"
           [tableId]="tableId"
           [prefixStorageKey]="storageKey">
    <ng-container button-bar-left>
        <nuc-input prefix="nucicon_search"
                   class="search"
                   (debounceValueChanged)="onAssetSearchChanged($event)"
                   placeholder="Search assets">
        </nuc-input>
        <data-filter
            [dataFilterContext]="dataFilterContextAsset"
            [filters]="dataFilters">
        </data-filter>
    </ng-container>
    <paginator button-bar-right
               [viewId]="tableId"
               [loading]="!assetsSubscription?.closed"
               [pageSizeOptions]="pageSizeOptions"
               [disablePageSizeOptions]="!!searchValue"
               [disableNextPage]="disableNextPage">
    </paginator>
</nuc-table>
