<form [formGroup]="formGroup">

    <nuc-form-field label="Field name">
        <nuc-input
            formControlName="name"
            placeholder="Enter field name"
            required>
        </nuc-input>
        <nuc-error *ngIf="formGroup.controls.name.invalid &&
                          formGroup.controls.name.dirty &&
                          formGroup.controls.name.hasError('required')">
            {{VALIDATOR_MESSAGES.REQUIRED}}
        </nuc-error>
        <nuc-error *ngIf="formGroup.controls.name.invalid &&
                          formGroup.controls.name.dirty &&
                          formGroup.controls.name.hasError('pattern')">
            {{VALIDATOR_MESSAGES.DATA_FIELD_NAME}}
        </nuc-error>
    </nuc-form-field>

    <nuc-form-field label="Data type">
        <nuc-dropdown
            formControlName="type"
            placeholder="Select a data type"
            [items]="dataTypeOptions"
            required>
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.type.value.getValue() === DATA_FIELD_TYPES.NUMBER">
        <nuc-checkbox formControlName="formatter" text="Currency"></nuc-checkbox>
    </nuc-form-field>

    <nuc-form-field label="Items" *ngIf="formGroup.controls.type.value.getValue() === DATA_FIELD_TYPES.ENUM">
        <nuc-suggestive-input
            formControlName="items"
            placeholder="Add each item separately."
            required>
        </nuc-suggestive-input>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.type.value.getValue() === DATA_FIELD_TYPES.ENUM">
        <nuc-checkbox formControlName="multiSelect" text="Multi select"></nuc-checkbox>
    </nuc-form-field>

    <nuc-form-field class="span-2" label="Enable variants">
        <nuc-checkbox formControlName="enableVariants" text="This allows the field to have variants"></nuc-checkbox>
    </nuc-form-field>

    <nuc-form-field class="span-2" label="Show in filter">
        <nuc-checkbox formControlName="showInFilter" text="This shows the field as an option for the filter"></nuc-checkbox>
    </nuc-form-field>

    <nuc-form-field class="span-2" label="Enable autocomplete" *ngIf="formGroup.controls.type.value.getValue() === DATA_FIELD_TYPES.STRING">
        <nuc-checkbox formControlName="enableAutocomplete" text="This will enable autocomplete and show the input as a list selection"></nuc-checkbox>
    </nuc-form-field>

</form>
