import {Component, DestroyRef, inject, Input} from '@angular/core';
import {EDataFieldTypes} from '../../../app.enums';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {DataFilterModel, EDataFilterModels} from '../../../models/ui/data-filter.model';
import {DataFilterUtil} from '../data-filter.util';
import {DataFieldFilterModel} from '../../../models/ui/data-field-filter.model';
import {Toaster} from '../../../classes/toaster.class';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {IDataFieldFilterOptions} from '../../../api/services/data-fields.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RDModule} from '@relayter/rubber-duck';
import {LowerCasePipe} from '@angular/common';
import {DataFilterSuggestiveComponent} from '../data-filter-suggestive/data-filter-suggestive.component';
import {DataFieldDropdownComponent} from '../data-field-dropdown/data-field-dropdown.component';
import {DataFilterMultiselectComponent} from '../data-filter-multiselect/data-filter-multiselect.component';
import {DataFilterDropdownComponent} from '../data-filter-dropdown/data-filter-dropdown.component';

@Component({
    selector: 'data-filter-template',
    templateUrl: './data-filter-template.component.html',
    styleUrls: ['./data-filter-template.component.scss'],
    standalone: true,
    imports: [
        RDModule,
        ReactiveFormsModule,
        LowerCasePipe,
        DataFieldDropdownComponent,
        DataFilterMultiselectComponent,
        DataFilterDropdownComponent,
        DataFilterMultiselectComponent,
        DataFilterSuggestiveComponent
    ]
})
export class DataFilterTemplateComponent {
    protected dataFieldsService = inject(DataFieldsApiService);
    private destroyRef = inject(DestroyRef);

    @Input() filtersForm: FormGroup<Record<string, any>>;
    @Input() public filters: DataFilterModel[] = [];
    @Input() public activeFilterCount: number = 0;
    @Input() public requestOptions: IDataFieldFilterOptions = {};
    @Input() public variantKey: string;

    public dataFieldData: Record<string, DropdownItem<string>[]> = {};
    public FILTER_DATA_TYPES = {...EDataFieldTypes};
    public EDataFilterModels = EDataFilterModels;

    public booleanDropdownItems = DataFilterUtil.booleanDropdownItems;

    public search(searchValue: string, dataFilter: DataFilterModel): void {
        const dataFieldId = (dataFilter as DataFieldFilterModel).dataFieldId;
        if (!searchValue || searchValue.trim() === '') {
            this.dataFieldData[dataFieldId] = [];
            return;
        }

        // Get dataField values
        this.dataFieldsService.getDataFieldValues(dataFieldId, this.requestOptions, 100, 0, searchValue,
            this.variantKey ? this.variantKey : null)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.dataFieldData[dataFieldId] = result.items.map((item) => {
                        return new DropdownItem<string>(item.displayName, item.displayName);
                    })
                },
                error: Toaster.error
            })
    }
}
