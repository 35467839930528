<ng-container *ngIf="permissions.GET_CAMPAIGN_ITEMS | userIsAllowedTo; else noPermissionState">
    <nuc-table
        [emptyStateTitle]="briefingDataProvider.phraseValue ? 'No item matches your search criteria.' : 'You have to add a briefing item.'"
        [tableDataProvider]="briefingDataProvider"
        [columns]="columns"
        [actions]="actions"
        [selectionMode]="selectionMode"
        [editMode]="!tableLocked"
        (itemEdited)="onItemEdited($event)"
        [tableId]="tableId"
        [prefixStorageKey]="storageKey"
        [tableSortOptions]="briefingDataProvider.sortOptions"
        (tableSortOptionsChange)="resetPageIndex()"
        (actionClicked)="actionClicked.emit($event)"
        [stickyHeader]="stickyHeader">
        <ng-container button-bar-left>
            <nuc-suggestive-input
                [options]="autocompleteValues"
                prefix="nucicon_search"
                [enableNewValue]="false"
                (searchTermChange)="onSearchTermChanged($event)"
                (selectionChanged)="onSearchSelectionChanged($event)"
                placeholder="Search briefing">
            </nuc-suggestive-input>
            <data-filter [dataFilterContext]="EDataFilterContextCampaignItem"
                         [variantKey]="selectedVariant?.getValue()"
                         [requestOptions]="requestOptions">
            </data-filter>
        </ng-container>
        <ng-container button-bar-left-after
                      *ngIf="(briefingTableOptions.inlineEditing && (permissions.PUT_CAMPAIGN_ITEM | userIsAllowedTo)) || variantEnabled">
            <nuc-button-secondary
                *ngIf="briefingTableOptions.inlineEditing && (permissions.PUT_CAMPAIGN_ITEM | userIsAllowedTo)"
                [icon]="tableLocked ? 'nucicon_lock_fill': 'nucicon_unlock'"
                (mouseenter)="unlockTableTooltipText = tableLocked ? 'Unlock table' : 'Lock table'"
                (click)="tableLocked = !tableLocked"
                [nucTooltip]="unlockTableTooltipText">
            </nuc-button-secondary>
            <nuc-dropdown *ngIf="variantEnabled"
                          icon="nucicon_variant"
                          class="variant variant-highlight"
                          placeholder="Select variant"
                          [nullOption]=false
                          [data]="selectedVariant"
                          (dataChange)="selectVariant($event)"
                          [items]="variants">
            </nuc-dropdown>
        </ng-container>

        <ng-container button-bar-right>
            <ng-content select="[multi-actions]"></ng-content>
            <paginator [viewId]="viewId"
                       [disableNextPage]="briefingDataProvider.disableNextPage"
                       [loading]="briefingDataProvider.loading">
            </paginator>
        </ng-container>
        <nuc-badge-warning button-bar-footer @unlockedBadgeAnimation class="table-unlocked-label" *ngIf="!tableLocked">
            Table data is
            unlocked
        </nuc-badge-warning>
    </nuc-table>
</ng-container>

<ng-template #noPermissionState>
    <no-permission-state-component></no-permission-state-component>
</ng-template>
