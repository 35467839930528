import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ARPagedResponseDataModel} from '@relayter/core';
import {AssetModel, AssetPutModel} from '../../models/api/asset.model';
import {Observable} from 'rxjs';
import {NewCursor} from '../new-api-cursor';
import {ApiService} from './api.service';
import {TableSortOptions} from '../table-sort-options';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {AssetProductModel} from '../../models/api/asset-product.model';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {CampaignModel} from '../../models/api/campaign.model';
import {ApiDeleteRequest} from '../requests/api-delete.request';
import {ApiPutRequest} from '../requests/api-put.request';

export enum EAssetJobType {
    EXPORT_IMAGE_JOB = 'EXPORT_IMAGE_JOB',
    EXPORT_ASSETS_JOB = 'EXPORT_ASSETS_JOB',
    ADD_DEFAULT_ASSETS_NEW = 'ADD_DEFAULT_ASSETS_NEW' // default s3Keys job to add assets
}

@Injectable({providedIn: 'root'})
export class AssetApiService extends ApiService<AssetModel> {
    private monitoredJobsService = inject(MonitoredJobsService);

    constructor() {
        super([ApiConstants.API_GROUP_ASSETS], AssetModel);
    }

    /**
     * Gets a single asset by id
     *
     * @param {string} id
     * @return {Observable<AssetProductModel>}
     */
    public getAsset(id: string): Observable<AssetProductModel> {
        const request = new ApiDetailsRequest(this.groups, AssetProductModel, id);

        return this.apiRequestService.findOne(request);
    }

    /**
     * Gets a paged response with an asset collection from the api server
     *
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} search
     * @param {TableSortOptions} [tableSortOptions]
     * @param {object} [filters]
     * @param {NewCursor} [cursor]
     * @param {string} [workflowComponentId]
     * @param {string[]} [productIds]
     * @returns {Observable<ARPagedResponseDataModel<AssetModel>>}
     */
    public getAssets(limit?: number, offset?: number, search?: string, tableSortOptions= new TableSortOptions(), filters?: Record<string, any>,
                     cursor?: NewCursor, productIds?: string[]): Observable<ARPagedResponseDataModel<AssetModel>> {
        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, tableSortOptions, cursor, search, filters);
        request.additionalQueryParams.addParam('productIds', productIds);

        return this.apiRequestService.find(request);
    }

    /**
     * Finds all 'not DONE' campaigns that are using an asset
     * @param {string} assetId
     * @param {number} [limit]
     * @param {number} [offset]
     * @returns {Observable<ARPagedResponseDataModel<CampaignModel>>}
     */
    public findCampaignsUsingAsset(assetId: string, limit?: number, offset?: number): Observable<ARPagedResponseDataModel<CampaignModel>> {
        const request = new ApiPagedRequest([...this.groups, assetId, ApiConstants.API_METHOD_ASSET_USAGE_IN_CAMPAIGNS], CampaignModel,
            limit, offset);

        return this.apiRequestService.find(request);
    }

    /**
     * Deletes an asset
     *
     * @param {string} assetId
     * @returns {Observable<boolean>}
     */
    public deleteAsset(assetId: string): Observable<boolean> {
        const request = new ApiDeleteRequest([...this.groups, assetId]);

        return this.apiRequestService.delete(request);
    }

    /**
     * Update asset
     *
     * @param {string} id
     * @param {AssetPutModel} asset
     * @returns {Observable<AssetModel>}
     */
    public putAsset(id: string, asset: AssetPutModel): Observable<AssetModel> {
        const request = new ApiPutRequest(this.groups, this.model, id, asset);

        return this.apiRequestService.put(request);
    }

    /**
     * Post asset job
     *
     * @param {EAssetJobType} jobType
     * @param {Record<string, any>} jobData
     * @returns {Observable<JobModel>}
     */
    public postJob(jobType: EAssetJobType, jobData: Record<string, any>): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);
        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
            );
    }
}
