import {DestroyRef, inject, Pipe, PipeTransform} from '@angular/core';
import {AppConstants} from '../app.constants';
import {environment} from '../../environments/environment';
import {
    formatDuration,
    hoursToMilliseconds,
    intervalToDuration,
    Locale,
    minutesToMilliseconds, secondsToMilliseconds
} from 'date-fns';
import {de as LOCALE_DE, enGB as LOCALE_EB_GB, fr as LOCALE_FR, nl as LOCALE_NL} from 'date-fns/locale';
import {ReplaySubject} from 'rxjs';

@Pipe({name: 'duration_words'})
export class DurationWordsPipe implements PipeTransform {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private timer: any;

    private subject = new ReplaySubject<string>(1);

    public transform(value?: Date): ReplaySubject<string> {
        this.destroyRef.onDestroy(() => {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.subject.complete();
        });

        this.setDurationTimer(value);

        return this.subject;
    }

    private setDurationTimer(value: Date): void {
        const options: Record<string, any> = {delimiter: this.getDelimiterLocale(), locale: this.getLocale()};

        const duration = new Date() > value
            ? intervalToDuration({start: value, end: new Date()})
            : intervalToDuration({start: new Date(), end:value })

        // Only show from the highest non-zero value
        const firstNonZeroIndex = Object.values(duration).findIndex(duration => !!duration);
        // Get max of 2 values to show
        const format = Object.keys(duration).slice(firstNonZeroIndex, firstNonZeroIndex + 2);
        if (format.length) {
            options.format = format;
        } else { // value and now are equal on seconds
            options.format = ['seconds'];
            options.zero = true;
        }

        const durationString = value ? formatDuration(duration, options) : null;
        this.subject.next(durationString);

        if (durationString) {
            const nextTimerStep = this.getNextTimerStep(format);
            if (nextTimerStep) {
                this.timer = setTimeout(() => this.setDurationTimer(value), nextTimerStep);
            }
        }
    }

    private getNextTimerStep(units: string[]): number {
        if (units.length === 1) return secondsToMilliseconds(1);

        switch (units[units.length - 1]) {
            case 'hours':
                return hoursToMilliseconds(1);
            case 'minutes':
                return minutesToMilliseconds(1);
            case 'seconds':
                return secondsToMilliseconds(1);
        }
    }

    private getLocale(): Locale {
        const locale = navigator.language || localStorage.getItem(AppConstants.LOCALSTORAGE_LOCALE) || environment.DEFAULT_LOCALE;

        switch (locale) {
            case 'de':
                return LOCALE_DE;
            case 'fr':
                return LOCALE_FR;
            case 'nl':
                return LOCALE_NL;
            default:
                return LOCALE_EB_GB;
        }
    }

    private getDelimiterLocale(): string {
        switch (this.getLocale()) {
            case LOCALE_DE:
                return ' und ';
            case LOCALE_FR:
                return ' et ';
            case LOCALE_NL:
                return ' en ';
            default:
                return ' and ';
        }
    }
}
