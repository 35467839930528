import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {SizeModel} from './size.model';
import {TemplateMarginsModel} from './template-margins.model';
import {EChannel} from '../../app.enums';
import {FileModel} from './file.model';

class MasterPageFilesModel {
    @autoserializeAs(FileModel) public preview: FileModel;
    @autoserializeAs(FileModel) public template: FileModel;
}

export class MasterPageModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public channel: EChannel;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserialize public pageCount: number;
    @autoserializeAs(SizeModel) public pageSize: SizeModel;
    @autoserializeAs(MasterPageFilesModel) public files: MasterPageFilesModel;
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class MasterPagePatchModel {
    public name: string;

    constructor(name: string) {
        this.name = name;
    }

}
