import {Deserialize} from 'cerialize';

export default function createDeserializeIdOrModel<T>(model: { new(): T }): (value: any) => T | T[] {
    return (value: any): T | T[] => {
        const convert = (value: any) : T => {
            if (typeof value === 'string') {
                return Deserialize({_id: value} as any, model);
            } else if (value && typeof value === 'object') {
                // Deserialize the object into an instance of the provided model.
                return Deserialize(value, model);
            }
        }
        if (Array.isArray(value)) {
            return value.map(convert)
        } else {
            return convert(value)
        }
    }
}
