import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ITagValueSelection} from '../../../../forms/rule-form/base-ruleset-item-form.component';
import {FileModel} from '../../../../models/api/file.model';

export class IndesignLibraryItemFiles {
    @autoserializeAs(FileModel) public readonly preview: FileModel;
}

export class DesignLibraryItemModel implements IDropdownItem, ITagValueSelection {
    @autoserialize public readonly name: string;
    @autoserialize public readonly tags: string[];
    @autoserializeAs(IndesignLibraryItemFiles) public readonly files: IndesignLibraryItemFiles;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this.name;
    }
}
