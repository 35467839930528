import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AppConstants} from '../../app.constants';
import {merge, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EPropertySettingsContext} from '../property-settings/property-settings.service';
import {DataFilterModel} from '../../models/ui/data-filter.model';
import {OverlayButtonComponent} from '@relayter/rubber-duck';
import {DataFilterUtil, EDataFilterContext} from '../data-filter/data-filter.util';

export enum OptionsMutationType {
    SortDirection,
    SortField
}

@Component({
    selector: 'rl-collection-options-view',
    templateUrl: 'collection-options-view.component.html',
    styleUrls: ['collection-options-view.component.scss']
})
export class CollectionOptionsViewComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild(OverlayButtonComponent) public overlayButton: OverlayButtonComponent;
    @Input() public advancedFilter: boolean;
    @Input() public propertySettingsContext: EPropertySettingsContext;
    @Input() public isLoading: boolean;
    @Input() public title: string = '';
    @Input() public searchPlaceholder: string;
    @Input() public searchValue: string;
    @Input() public disableNextPage = false;
    @Input() public viewId: string;
    @Input() public disableSort = false;
    @Input() public disablePageOptions = false;

    @Output() public sortChange: EventEmitter<DropdownItem<string>> = new EventEmitter<DropdownItem<string>>();
    @Output() public sortDescendingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public optionsChanged = new EventEmitter();
    @Output() public onSearch = new EventEmitter<string>();

    @Input() public get sort(): DropdownItem<string> { return this._sort; }
    public set sort(val) {
        this._sort = val;
        this.sortChange.emit(val);
    }

    @Input() public get sortingOptions(): DropdownItem<string>[]  { return this._sortingOptions; }
    public set sortingOptions(options: DropdownItem<string>[]) {
        this._sortingOptions = options;
        this._sort = this._sortingOptions.find(option => option.getValue() === this.sort?.getValue()) || this._sortingOptions[0];
        this.formGroup?.controls.sort.patchValue(this._sort);
    }

    @Input() public get sortDescending(): boolean { return this._sortDescending; }
    public set sortDescending(val) {
        this._sortDescending = val;
        this.sortDescendingChange.emit(val);
    }

    public formGroup: UntypedFormGroup;

    private _sort: DropdownItem<string>;
    private _sortingOptions: DropdownItem<string>[];
    private _sortDescending: boolean;
    private onDestroySubject = new Subject<void>();

    public dataFilterContext: EDataFilterContext;
    public dataFilters: DataFilterModel[] = [];

    /**
     * On init, set the default sort on the first option, subscribe to the query params of the route and change the mode when available
     */
    public ngOnInit(): void {
        if (this.sort || this.sortingOptions) {
            this.sort = this.sort || this.sortingOptions[0];
        }

        // Set data fields context
        switch (this.propertySettingsContext) {
            case EPropertySettingsContext.PRODUCT:
                this.dataFilterContext = EDataFilterContext.PRODUCT;
                break;
            case EPropertySettingsContext.BRIEFING:
                this.dataFilterContext = EDataFilterContext.CAMPAIGN_ITEM;
                break;
            case EPropertySettingsContext.ASSET:
                this.dataFilterContext = EDataFilterContext.ASSET;
                break;
        }

        this.dataFilters.push(...DataFilterUtil.setupDefaultDataFilters(this.dataFilterContext));

        this.initForm();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disableSort || changes.isLoading) {
            this.disableSort || this.isLoading ? this.formGroup?.controls.sort.disable({emitEvent: false}) :
                this.formGroup?.controls.sort.enable({emitEvent: false});
        }
    }

    public initForm(): void {

        this.formGroup = new UntypedFormGroup({
            sort: new UntypedFormControl({value: this.sort, disabled: this.disableSort || this.isLoading})
        });

        merge(this.formGroup.valueChanges).pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((value) => {
            const sort = value.sort;
            let type: OptionsMutationType;

            if (sort && this.sort !== sort) {
                this.sort = sort;
                type = OptionsMutationType.SortField;
            }

            this.optionsChanged.emit(type);
        });
    }

    /**
     * Responder function for sorting direction, emit changed options
     */
    public onSortingDirectionClicked(): void {
        this.sortDescending = !this.sortDescending;
        this.optionsChanged.emit(OptionsMutationType.SortDirection);
    }

    /**
     * Get the sorting direction icon based on the ascending option
     * @param {boolean} ascending
     * @returns {string}
     */
    public getSortingDirectionIcon(ascending: boolean): string {
        return ascending ? AppConstants.ICONS.ASC : AppConstants.ICONS.DESC;
    }

    public onSearchBarValueUpdated(value: string): void {
        this.onSearch.emit(value);
    }
}
