import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {ApiConstants} from '../api.constant';
import {
    DesignLibraryDetailModel,
    DesignLibraryModel
} from '../../modules/static-content-rulesets/models/api/design-library.model';
import {Observable} from 'rxjs';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {ItemUsedModel} from '../../models/api/item-used.model';
import {ApiGetRequest} from '../requests/api-get.request';

export enum ELibraryJobTypes {
    CREATE_INDESIGN_LIBRARY = 'CREATE_INDESIGN_LIBRARY',
    UPDATE_INDESIGN_LIBRARY_JOB = 'UPDATE_INDESIGN_LIBRARY_JOB',
    CREATE_SVG_LIBRARY = 'CREATE_SVG_LIBRARY',
    UPDATE_SVG_LIBRARY = 'UPDATE_SVG_LIBRARY',
}

export interface IUpdateLibraryJobData {
    libraryId: string;
    s3Key: string;
    name?: string;
    fonts?: string[];
}

export interface ICreateLibraryJobData {
    name: string;
    fonts: string[];
    s3Key: string;
}

export type ILibraryJobData = ICreateLibraryJobData | IUpdateLibraryJobData;

@Injectable({providedIn: 'root'})
export class DesignLibraryApiService extends ApiService<DesignLibraryModel> {
    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_DESIGN_LIBRARIES], DesignLibraryModel);
    }

    public getDesignLibraryDetails(itemId: string): Observable<DesignLibraryDetailModel> {
        const request = new ApiDetailsRequest(this.groups, DesignLibraryDetailModel, itemId);
        return this.apiRequestService.findOne<DesignLibraryDetailModel>(request);
    }

    public postJob(jobType: string, jobData: ILibraryJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);
        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
            );
    }

    public getDesignLibraryInUse(designLibraryId: string): Observable<ItemUsedModel> {
        const request = new ApiGetRequest(
            [...this.groups, designLibraryId, ApiConstants.API_METHOD_USAGE],
            ItemUsedModel
        )
        return this.apiRequestService.get<ItemUsedModel>(request);
    }
}
