@if (filtersForm) {
    <form [formGroup]="filtersForm">
        @for (dataFilter of filters; track dataFilter) {
            <div class="data-field-filter-input">
                @if (dataFilter.showCaption) {
                    <p class="body-strong">{{ dataFilter.displayName }}</p>
                }
                <div class="input-field"
                     [class.flex-input-field]="dataFilter.modelName === EDataFilterModels.DATA_FILTER && dataFilter.type === FILTER_DATA_TYPES.LIST">
                    @if (dataFilter.modelName === EDataFilterModels.DATA_FILTER && dataFilter.type === FILTER_DATA_TYPES.STRING) {
                        <nuc-input
                            pattern="^[a-zA-Z0-9].*"
                            type="text"
                            [prefix]="'nucicon_search'"
                            [formControlName]="dataFilter.property"
                            [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''">
                        </nuc-input>
                    } @else if (dataFilter.type === FILTER_DATA_TYPES.NUMBER) {
                        <nuc-input type="number"
                                   [prefix]="'nucicon_search'"
                                   [formControlName]="dataFilter.property"
                                   [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''">
                        </nuc-input>
                    } @else if (dataFilter.type === FILTER_DATA_TYPES.ENUM) {
                        @if (dataFilter.enumeration?.multiSelect) {
                            <nuc-dropdown-multiselect [formControlName]="dataFilter.property"
                                                      [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''"
                                                      [items]="dataFilter.items">
                            </nuc-dropdown-multiselect>
                        } @else {
                            <nuc-dropdown [formControlName]="dataFilter.property"
                                          [items]="dataFilter.items"
                                          [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''">
                            </nuc-dropdown>
                        }
                    } @else if (dataFilter.type === FILTER_DATA_TYPES.BOOLEAN) {
                        @if (dataFilter.showBooleanAsDropdown) {
                            <nuc-dropdown [formControlName]="dataFilter.property"
                                          [items]="booleanDropdownItems"
                                          [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''">
                            </nuc-dropdown>
                        } @else {
                            <nuc-checkbox
                                [text]="dataFilter.displayName"
                                textPosition="start"
                                [formControlName]="dataFilter.property"
                                [nucTooltip]="dataFilter.tooltip">
                            </nuc-checkbox>
                        }
                    } @else if (dataFilter.type === FILTER_DATA_TYPES.DATE) {
                        <nuc-datepicker [formControlName]="dataFilter.property"
                                        [placeholder]="!dataFilter.disabled ? 'Select the ' + (dataFilter.displayName | lowercase) : ''">
                        </nuc-datepicker>
                    } @else if (dataFilter.modelName === EDataFilterModels.DATA_FILTER && dataFilter.type === FILTER_DATA_TYPES.LIST) {
                        <rl-data-filter-suggestive
                            [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''"
                            [dataFilter]="dataFilter"
                            [formControlName]="dataFilter.property">
                        </rl-data-filter-suggestive>
                    } @else if (dataFilter.modelName === EDataFilterModels.DATA_FIELD_FILTER) {
                        @if (dataFilter.enableAutocomplete && dataFilter.type === FILTER_DATA_TYPES.STRING) {
                            <nuc-suggestive-string-input
                                [formControlName]="dataFilter.property"
                                [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''"
                                [enableNewValue]="false"
                                [prefix]="'nucicon_search'"
                                (searchTermChange)="search($event, dataFilter)"
                                [options]="dataFieldData[dataFilter.dataFieldId] || []">
                            </nuc-suggestive-string-input>
                        } @else if (!dataFilter.enableAutocomplete && dataFilter.type === FILTER_DATA_TYPES.STRING) {
                            <nuc-input [formControlName]="dataFilter.property"
                                       [type]="dataFilter.type"
                                       [prefix]="'nucicon_search'"
                                       [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''">
                            </nuc-input>
                        } @else if (dataFilter.type === FILTER_DATA_TYPES.LIST) {
                            <nuc-suggestive-input [formControlName]="dataFilter.property"
                                                  [placeholder]="!dataFilter.disabled ? 'Fill in the ' + (dataFilter.displayName | lowercase) : ''"
                                                  [enableNewValue]="false"
                                                  [prefix]="'nucicon_search'"
                                                  (searchTermChange)="search($event, dataFilter)"
                                                  [options]="dataFieldData[dataFilter.dataFieldId] || []">
                            </nuc-suggestive-input>
                        }
                    }
                </div>
            </div>
        }
    </form>
}
