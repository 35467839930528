import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {IProductApiModel, ProductModel} from '../../models/api/product.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiError, ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions, ARResponseModel} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {LinkedAssetsToProductsModel} from '../../models/api/linked-assets-to-products.model';
import {QueryParams} from '../../classes/query-params';
import {Cursor} from '../api-cursor';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {NewCursor} from '../new-api-cursor';
import {IExportDataFieldCollectionJobData} from './data-fields.service';

export enum EProductJobTypes {
    PRODUCT_IMPORT = 'PRODUCT_IMPORT',
    PRODUCT_EXPORT_JOB = 'PRODUCT_EXPORT_JOB',
}

export interface IProductImportJobData {
    identifier: string;
    s3Key: string;
}

export type IProductJobData = IProductImportJobData | IExportDataFieldCollectionJobData;

@Injectable({
    providedIn: 'root'
})
export class ProductService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    /**
     * Gets a paged list of products from the API
     * @param {boolean} sortDescending
     * @param {string} sort
     * @param {string} search
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {object} [filters]
     * @param {Cursor | NewCursor} [cursor]
     * @returns {Observable<ARPagedResponseDataModel<ProductModel>>}
     */
    public getData(sortDescending: boolean, sort: string, search: string, limit?: number, offset?: number,
                   filters?: Record<string, any>, cursor?: Cursor | NewCursor): Observable<ARPagedResponseDataModel<ProductModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sort, sortDescending ? ApiConstants.API_QUERY_VALUE_DESC : ApiConstants.API_QUERY_VALUE_ASC)
            .setSearchParams(search)
            .addObject(filters);

        if (cursor && cursor instanceof Cursor) queryParams.setCursor(cursor);
        else if (cursor && cursor instanceof NewCursor) queryParams.setNewCursor(cursor);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_PRODUCTS], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, ProductModel);
        });
    }

    public getProductIds(search: string, limit: number): Observable<string[]> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit)
            .setSearchParams(search)
            .addParam('includeTotals', false);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_PRODUCT_IDS], queryParams.getParams());
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.doRequest(options).subscribe(
                (res: ARResponseModel) => {
                    obs.next(res.data as string[]);
                    obs.complete();
                },
                (err: ARApiError) => {
                    this.handleError(err, obs);
                });
        });
    }

    /**
     * Delete a product through the API
     * @param id
     * @returns {Observable<boolean>}
     */
    public deleteProduct(id: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PRODUCTS, `/${id}`]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Get a specific product by its ID from the API
     * @param id
     * @returns {Observable<ProductModel>}
     */
    public getProductById(id: string): Observable<ProductModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PRODUCTS, `/${id}`]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, ProductModel);
        });
    }

    public postProduct(product: IProductApiModel): Observable<ProductModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PRODUCTS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = product;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, ProductModel);
        });
    }

    public patchProduct(id: string, product: IProductApiModel): Observable<ProductModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PRODUCTS, id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = product;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, ProductModel);
        });
    }

    /**
     * Link assets to products through the API
     * @param {LinkedAssetsToProductsModel} linkedAssetsToProducts
     * @returns {Observable<boolean>}
     */
    public linkAssetsToProducts(linkedAssetsToProducts: LinkedAssetsToProductsModel): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PRODUCTS,
            ApiConstants.API_METHOD_LINK_ASSETS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = linkedAssetsToProducts;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public postJob(jobType: EProductJobTypes, jobData: IProductJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_PRODUCTS,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => this.handleDetailResponse(options, obs, JobModel))
            .pipe(tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job)));
    }
}
