import {Component, DestroyRef, inject, Inject, Input, OnInit, Optional} from '@angular/core';
import {CustomWorkflowStepModel} from '../../../../../../models/api/custom-workflow-step.model';
import {WorkflowConfigurationModel} from '../../../../../../models/api/workflow-configuration.model';
import {PublicationModel} from '../../../../../../models/api/publication.model';
import {StickyNoteModel} from '../../../../../../models/api/sticky-note.model';
import {AppConstants} from '../../../../../../app.constants';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {delay, filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {TabBarItemModel} from '../../../../../../models/ui/tab-bar-item.model';
import {
    CustomWorkflowActionModel,
    EStickyNoteActionName
} from '../../../../../../models/api/custom-workflow-action.model';
import {UserService} from '../../../../../../api/services/users.service';
import {StickyNoteUtil} from '../sticky-note.util';
import {ARApiError, ARLogger} from '@relayter/core';
import {Toaster} from '../../../../../../classes/toaster.class';
import {UserIsAllowedToPipe} from '../../../../../../pipes/user-is-allowed-to.pipe';
import {CustomWorkflowComponentModel} from '../../../../../../models/api/custom-workflow-component.model';
import {WorkflowFiltersUtil} from '../workflow-filters.util';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    ISortOptionEvent,
    ITableColumn,
    ITableItem,
    NUC_FULL_MODAL_DATA,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {RLDatePipe} from '../../../../../../pipes/rl-date.pipe';
import {SortDirection} from '@angular/material/sort';
import {DropdownItem} from '../../../../../../models/ui/dropdown-item.model';
import {CursorArray} from '../../../../../../api/api-cursor';
import {CustomWorkflowFilterModel} from '../../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../../models/api/custom-workflow-filter-option.model';
import {NullUndefinedPipe} from '../../../../../../pipes/null-undefined.pipe';
import {RLTableComponent} from '../../../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {AdvancedFiltersDataService} from '../../../../../../api/services/advanced-filters.data-service';
import {IQueryParam} from '../../../../../../classes/query-params';
import {EDataCollectionName, EDataFieldTypes, EStickyNoteStatus} from '../../../../../../app.enums';
import {DataFilterModel} from '../../../../../../models/ui/data-filter.model';
import {
    INoteExportFormData,
    NoteExportFormComponent
} from '../../../../../../forms/note-export-form/note-export-form.component';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {DataFieldsApiService} from '../../../../../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../../../../../models/api/data-field.model';
import {DataFieldsComponentUtil} from '../../../../../../classes/data-fields-component.util';
import {EWorkflowComponentOptionName} from '../../../../../../models/api/custom-workflow-option.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
    StickyNotesDataService
} from '../custom-workflow-preview/preview-sticky-notes-sidebar/sticky-notes-data.service';

export interface ICustomWorkflowStickyLogModalData {
    publication: PublicationModel;
    workflow: WorkflowConfigurationModel;
    component: CustomWorkflowComponentModel;
    isStickyLog: boolean;
    activeVariant: VariantModel;
    publicationVariants: VariantModel[];
}

export interface ISortOption {
    sortProperty: string;
    sortDuplicates: boolean;
}

const CREATED_BY_FULL_NAME = 'createdBy.fullName';

@Component({
    selector: 'rl-custom-workflow-sticky-log-component',
    templateUrl: './custom-workflow-sticky-list-log.component.html',
    styleUrls: ['./custom-workflow-sticky-list-log.component.scss'],
    providers: [AdvancedFiltersDataService, PaginatorService, StickyNotesDataService]
})
export class CustomWorkflowStickyLogComponent extends RLTableComponent implements OnInit {
    public destroyRef = inject(DestroyRef);
    private stickyNoteDataService = inject(StickyNotesDataService);

    public tableId = 'custom-workflow-sticky-log-table';
    public viewId: string;

    public readonly ESelectionMode = ESelectionMode;
    public readonly DELETED_USER = NullUndefinedPipe.defaultValues.DELETED_USER;

    public static readonly TAB_INDEXES = {
        COMMENTS: 0,
        CHANGELOG: 1,
        NOTE_CHANGES: 2
    };

    public allTabs: TabBarItemModel[] = [
        new TabBarItemModel('Comments', CustomWorkflowStickyLogComponent.TAB_INDEXES.COMMENTS),
        new TabBarItemModel('Briefing changes', CustomWorkflowStickyLogComponent.TAB_INDEXES.CHANGELOG),
        new TabBarItemModel('Note changes', CustomWorkflowStickyLogComponent.TAB_INDEXES.NOTE_CHANGES)
    ];
    public tabBarItems: TabBarItemModel[];
    public disabledTabBarItems: TabBarItemModel[] = [];

    private _selectedTab;
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab && this.tabBarItems.find((t) => t.title === tab.title)) this._selectedTab = tab;
    }

    @Input() public publication: PublicationModel;
    @Input() public workflow: WorkflowConfigurationModel;
    @Input() public step: CustomWorkflowStepModel;
    @Input() public isStickyLog: boolean;
    @Input() public component: CustomWorkflowComponentModel;
    @Input() public activeFilters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>;
    public publicationVariants: VariantModel[];

    public pageIndex: number;
    public pageSize: number;

    public dataFilters: DataFilterModel[];

    private _sortOption: ISortOption;
    public set sortOption(sortOption: ISortOption) {
        this._sortOption = sortOption;
    };

    public get sortOption() {
        let sortColumn = null;
        if (this._sortOption) sortColumn = Object.assign({}, this._sortOption);
        return sortColumn;
    };

    public sortOrder: SortDirection;
    public disableNextPage = true;
    public loading = true;
    public actionLoading = false;
    private cursorArray: CursorArray;

    public refresh$ = new ReplaySubject<void>(1);
    public hasNext = false;
    public searchValue: string;

    public activeVariant: VariantModel;

    private get filters(): IQueryParam[] {
        let filters = WorkflowFiltersUtil.fromActiveFilters(this.activeFilters);
        const componentFilter = this.getFilterValues();
        if (componentFilter) {
            filters = filters.concat(componentFilter);
        }
        if (this.activeVariant) {
            filters.push({queryParam: 'variant', value: this.activeVariant._id});
        }
        return filters;
    }

    public stickyNotes$ = this.refresh$.pipe(
        delay(0), // Prevents changeDetection error
        tap(() => this.loading = true),
        filter(() => !!this.publication && (!!this.step || this.isStickyLog)),
        switchMap(() => {
            const cursor = this.cursorArray.getCursor(this.pageIndex);

            return this.publicationService.getStickyNotesForPublication(this.publication._id,
                this.step?._id,
                this.filters,
                cursor,
                this.pageSize,
                0,
                this.sortOption?.sortProperty,
                this.sortOrder,
                this.searchValue);
        }),
        tap((result) => {
            this.loading = false;
            this.actionLoading = false;
            this.clickedStickyNote$.next(null);
            this.disableNextPage = !result.hasNext;
            this.hasNext = result.hasNext;
            let totalItems = result.items.length.toString();
            if (result.hasNext) totalItems = totalItems + '+'
            this.stickyNoteDataService.setTotalItemsInList(totalItems);

            if (result.items.length > 0) {
                this.cursorArray.setCursor(this.pageIndex, this.sortOption?.sortProperty, result.items[result.items.length - 1]);
            }
        }),
        map((result) => result.items),
        shareReplay(1),
        takeUntilDestroyed(this.destroyRef)
    );

    public clickedStickyNote$: Subject<ITableItem> = new Subject();
    public selectedStickyNote$: Observable<StickyNoteModel> = combineLatest([this.stickyNotes$, this.clickedStickyNote$]).pipe(
        map(([stickyNotes, selectedStickyNote]) =>
            stickyNotes && selectedStickyNote ? stickyNotes.find((stickyNote) => stickyNote._id === selectedStickyNote._id) : null),
        tap((stickyNote) => {
            if (stickyNote) {
                this.updateAllowedActions(stickyNote);
                this.updateTabs();
            }
        }),
        takeUntilDestroyed(this.destroyRef)
    );

    public columns: ITableColumn[] = [];
    private fileNameIndex = 1;
    public defaultColumns: ITableColumn[] = [
        {
            title: 'User',
            key: CREATED_BY_FULL_NAME,
            sortProperty: CREATED_BY_FULL_NAME,
            sortDuplicates: true,
            format: (value) => NullUndefinedPipe.transform(value, NullUndefinedPipe.defaultValues.DELETED_USER)
        },
        {
            title: 'Filename',
            key: 'publicationItem.publicationItemId',
            sortProperty: 'publicationItem.publicationItemId',
            sortDuplicates: true
        },
        {
            title: 'Message',
            key: 'message',
            size: EColumnSize.LARGE,
            sortProperty: 'message',
            sortDuplicates: true
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Status',
            key: 'status',
            sortProperty: 'status',
            sortDuplicates: true
        }
    ];

    private pageNumberColumn = {
        title: 'Page numbers',
        key: 'publicationItem.formattedPageNumbers',
        type: EColumnType.DEFAULT,
        sortProperty: 'publicationItem.firstPageNumber'
    };

    public readonly TOOLTIPS = AppConstants.STICKY_NOTE_TOOLTIPS;
    public readonly TAB_INDEXES = CustomWorkflowStickyLogComponent.TAB_INDEXES;
    public readonly PERMISSIONS = AppConstants.PERMISSIONS;

    public editStatusActions: CustomWorkflowActionModel[] = [];
    public markDuplicateAction: CustomWorkflowActionModel;
    public deleteAction: CustomWorkflowActionModel;
    public showChangelogsAction: CustomWorkflowActionModel;
    public addCommentAction: CustomWorkflowActionModel;
    public showCommentsAction: CustomWorkflowActionModel;

    // Filters setup
    public filterValues: IQueryParam[];
    private firstLoad: boolean = true;
    private campaignDataFields: DataFieldModel[];

    protected dataFieldsComponentUtil: DataFieldsComponentUtil = inject(DataFieldsComponentUtil);

    constructor(protected publicationService: PublicationsService,
                protected userService: UserService,
                protected userIsAllowedToPipe: UserIsAllowedToPipe,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) protected modalData: ICustomWorkflowStickyLogModalData,
                protected dialogService: NucDialogService,
                protected fullModalService: FullModalService,
                userSettingsStorageService: UserSettingsStorageService,
                private filtersDataService: AdvancedFiltersDataService,
                protected paginatorService: PaginatorService,
                protected dataFieldService: DataFieldsApiService) {
        super(userSettingsStorageService);

        if (modalData) {
            this.workflow = modalData.workflow;
            this.publication = modalData.publication;
            this.component = modalData.component;
            this.isStickyLog = modalData.isStickyLog;
            this.activeVariant = modalData.activeVariant;
            this.publicationVariants = modalData.publicationVariants;
        }
    }

    public ngOnInit(): void {
        this.viewId = this.tableId;

        if (this.publicationVariants?.length) {
            this.defaultColumns.push({
                title: 'Variant',
                key: 'variant.name',
                sortProperty: 'variant.name'
            });
        }

        this.dataFieldService.getCampaignItemDataFields()
            .pipe(
                take(1),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(dataFields => {
                this.campaignDataFields = dataFields;
                this.setupTableColumns();
            });

        this.setupDataFilters();

        this.filtersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((values) => {
                this.filterValues = [];
                for (const key of Object.keys(values)) {
                    this.filterValues.push({queryParam: key, value: values[key]});
                }

                if (!this.firstLoad) {
                    this.paginatorService.setPageIndex(this.viewId, 1); // set the initial pageIndex
                }

                this.firstLoad = false;
            });

        this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.cursorArray = new CursorArray(pagination.pageIndex, this.sortOption?.sortDuplicates);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.refresh$.next(); // get data
        });

        this.refreshData();
    }

    public setupDataFilters(): void {
        const statusOptions = Object.keys(EStickyNoteStatus)
                .filter((status) => status !== EStickyNoteStatus.NEW)
                .map(key => new DropdownItem(key, key));

        this.dataFilters = [
            new DataFilterModel(
                'Status',
                'statuses',
                EDataFieldTypes.ENUM, statusOptions
            ),
            new DataFilterModel(
                'User name',
                CREATED_BY_FULL_NAME,
                EDataFieldTypes.LIST,
                null,
                null,
                {publication: this.publication._id, ['publicationItem.step']: this.step?._id},
                EDataCollectionName.STICKY_NOTE
            )
        ];
    }

    /**
     * Updates the action booleans
     */
    private updateAllowedActions(stickyNote: StickyNoteModel): void {
        const actions = this.component.actions.filter((action) => this.userIsAllowedToPipe.transform(action.permissions));
        const user = this.userService.getLoggedInUser();

        this.editStatusActions =
            StickyNoteUtil.findEditStatusActions(stickyNote, actions)
                .filter((action) => ![EStickyNoteStatus.DELETED, EStickyNoteStatus.DUPLICATE].includes(action.to));

        this.markDuplicateAction =
            StickyNoteUtil.findEditStatusAction(EStickyNoteStatus.DUPLICATE, stickyNote, actions);
        this.showChangelogsAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.SHOW_NOTE_BRIEFING_CHANGELOG, stickyNote, actions);
        this.addCommentAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.ADD_COMMENT, stickyNote, actions);
        this.showCommentsAction =
            StickyNoteUtil.findAction(EStickyNoteActionName.SHOW_COMMENTS, stickyNote, actions);
        this.deleteAction =
            StickyNoteUtil.findEditStatusAction(EStickyNoteStatus.DELETED, stickyNote, actions) ||
            StickyNoteUtil.findEditOwnStatusAction(EStickyNoteStatus.DELETED, stickyNote, actions, user);
    }

    protected setupTableColumns(): void {
        // Need to wait for the data fields
        if (!this.campaignDataFields) {
            return;
        }

        // table columns
        this.columns = [...this.defaultColumns];
        if (this.publication.workflow.sequentialNumbering) {
            // Insert after file name column
            this.columns.splice(this.fileNameIndex + 1, 0, this.pageNumberColumn);
        }
        const columnConfig = this.component.options?.find((option) => option.name === EWorkflowComponentOptionName.COLUMN_CONFIG);
        this.columns = this.columns.concat(this.dataFieldsComponentUtil.getDataFieldsColumnList(this.campaignDataFields, columnConfig,
            this.activeVariant?.key));
    }

    /**
     * Updates the tabs based on the allowed actions for the sticky note
     */
    private updateTabs(): void {
        this.tabBarItems = this.allTabs.filter((tab) =>
            (tab.index === CustomWorkflowStickyLogComponent.TAB_INDEXES.CHANGELOG && this.showChangelogsAction) ||
            (tab.index === CustomWorkflowStickyLogComponent.TAB_INDEXES.COMMENTS && this.showCommentsAction) ||
            (tab.index === CustomWorkflowStickyLogComponent.TAB_INDEXES.NOTE_CHANGES)
        );
        if (this.tabBarItems.length > 0) {
            this.selectedTab = this.selectedTab && this.tabBarItems.includes(this.selectedTab) ? this.selectedTab : this.tabBarItems[0];
        } else {
            this.selectedTab = null;
        }
    }

    /**
     * Responds to action button clicks
     * @param {StickyNoteModel} stickyNote
     * @param {CustomWorkflowActionModel} action
     */
    public stickyNoteActionClicked(stickyNote: StickyNoteModel, action: CustomWorkflowActionModel): void {
        const stickyNoteUpdate = new StickyNoteModel();
        stickyNoteUpdate._id = stickyNote._id;
        switch (action.name) {
            case EStickyNoteActionName.EDIT_STICKY_NOTE_STATUS:
            case EStickyNoteActionName.EDIT_OWN_STICKY_NOTE_STATUS:
                stickyNoteUpdate.status = action.to;
                if (action.to === EStickyNoteStatus.DELETED) {
                    this.openDeleteStickyNoteDialog(stickyNoteUpdate);
                } else {
                    this.updateStickyNote(stickyNoteUpdate);
                }
                break;
            default:
                ARLogger.debug('Missing implementation for sticky action: ' + action);
                break;
        }
    }

    private openDeleteStickyNoteDialog(stickyNote: StickyNoteModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete note', 'Please confirm that you wish to delete this note.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.updateStickyNote(stickyNote);
        });
    }

    /**
     * Update the sticky note
     * @param {StickyNoteModel} stickyNote
     */
    private updateStickyNote(stickyNote: StickyNoteModel): void {
        this.actionLoading = true;
        this.publicationService.updateStickyNote(this.publication._id, stickyNote, this.component._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => this.refresh$.next(),
                error: (error: ARApiError) => {
                    this.actionLoading = false;
                    Toaster.handleApiError(error);
                }
            });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.sortOption = sortEvent.column as ISortOption;
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortOption = null;
            this.sortOrder = null;
        }
        this.refreshData();
    }

    protected refreshData(): void {
        this.paginatorService.setPageIndex(this.viewId, 1);
    }

    protected getFilterValues(): IQueryParam[] {
        return this.filterValues;
    }

    public openNoteExportModal(): void {
        const modalData: INoteExportFormData = {
            publicationId: this.publication._id,
            stepId: this.step?._id,
            filters: this.filters,
            sortProperty: this.sortOption?.sortProperty,
            sortOrder: this.sortOrder,
            cursor: this.cursorArray.getCursor(this.pageIndex),
            search: this.searchValue
        };

        const modalConfig = new FullModalConfig('Export notes',
            'Publication item notes (or a part) will be exported to the selected file type.', modalData);
        this.fullModalService.open(NoteExportFormComponent, modalConfig);
    }

    public setVariant(variant: VariantModel): void {
        if (this.activeVariant !== variant) {
            this.activeVariant = variant;
            this.setupTableColumns();
            this.refreshData();
        }
    }
}
