<nuc-overlay-button icon="nucicon_filter"
                    [showIndicator]="activeFilterCount > 0"
                    [disabled]="!filters.length"
                    [nucTooltip]="'Filter'"
                    [content]="advancedFilters">
</nuc-overlay-button>

<ng-template #advancedFilters>
    @if (dataFields) {
        <div class="content">
            <div class="header">
                <div class="filter-actions">
                    <div class="title">
                        <h2>Filter</h2>
                        <nuc-hint>{{ activeFilterCount }} active</nuc-hint>
                    </div>
                    <div class="buttons">
                        <nuc-button-secondary icon="nucicon_reset" [nucTooltip]="'Reset'"
                                              (click)="onReset()"></nuc-button-secondary>
                        <nuc-button-primary [disabled]="filtersForm?.pristine || !filtersForm?.valid" text="Apply"
                                            (click)="onApply()"></nuc-button-primary>
                    </div>
                </div>
                <hr>
            </div>

            <data-filter-template
                [filtersForm]="filtersForm"
                [filters]="filters"
                [activeFilterCount]="activeFilterCount"
                [requestOptions]="requestOptions"
                [variantKey]="variantKey"/>
        </div>
    }
</ng-template>
