import {Injectable} from '@angular/core';
import {ESelectionMode, ITableItem, TableDataProvider} from '@relayter/rubber-duck';
import {NewCursorArray} from '../api/new-api-cursor';
import {TableSortOptions} from '../api/table-sort-options';
import {SelectionModel} from '@angular/cdk/collections';

@Injectable()
export class RelayterTableDataProvider<T extends ITableItem> extends TableDataProvider<T> {
    protected apiCursor: NewCursorArray;
    public selectionMode: ESelectionMode;

    constructor(public sortOptions: TableSortOptions) {
        super();

        // Init api cursor
        this.apiCursor = new NewCursorArray(1, sortOptions);
    }
    public resetCursorArray(pageIndex = this.pageIndex): void {
        this.apiCursor.reset(pageIndex, this.sortOptions);
    }

    protected setCursor(): void {
        this.apiCursor.setCursor(this.pageIndex, this.data[this.data.length - 1]);
    }

    public initSelection(selectionMode?: ESelectionMode): void {
        if (selectionMode) this.selectionMode = selectionMode;

        this.selection = new SelectionModel<T>(this.selectionMode === ESelectionMode.MULTI);
    }
}
