.button-wrapper {
    width: 45px;
    height: 48px;
    display: block;
}

.test-fill {

    .st0{
        fill:#FFFFFF;
    }
    .st1{
        fill:#E5E5E5;
    }
    .st2{
        fill:#FF3366;
    }
    .st4{
        display:inline;
    }
    .st5{
        fill:#3FBB87;
    }
    .st6{
        fill:#FFFFFF;
    }
    .st7{
        mask:url(#mask0_158_7283_00000022551284694008448090000016848395785351100568_);
    }
    .st8 {
        mask: url(#mask_lines);
    }
    .box-shadow {
        filter:url(#shadow);
    }
}
.hidden {display: none;}
