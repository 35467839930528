import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CustomWorkflowActionModel, ETransitionTriggerActionName} from '../../../../../../../models/api/custom-workflow-action.model';
import {combineLatest, forkJoin, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {map, shareReplay, startWith, switchMap, take, takeUntil, withLatestFrom} from 'rxjs/operators';
import {ARLogger} from '@relayter/core';
import {FileTypeUtil} from '../../../../../../../classes/file-type.util';
import {AmazonService} from '../../../../../../../api/services/amazon.service';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {PublicationsService} from '../../../../../../../api/services/publications.service';
import {EUploadCategory, UploadGroupModel} from './upload-group.model';
import {EUploadStatus, UploadModel} from '../../../../../../../components/upload-file-component/upload.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {CustomWorkflowStepModel} from '../../../../../../../models/api/custom-workflow-step.model';
import {EPublicationDisplayProperties} from '../../../../../../../pipes/publication-item-display.pipe';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {RLValidatorRegExConstants} from '../../../../../../../classes/validators/rl-validator-regex.constant';
import {PublicationItemsApiService} from '../../../../../../../api/services/publication-items.api.service';

export interface IFilesUploadModalData {
    publication: PublicationModel;
    step: CustomWorkflowStepModel;
    actions: CustomWorkflowActionModel[];
    publicationVariants: VariantModel[];
}

export class KeyItemMapModel {
    constructor(public readonly s3Key: string, public readonly itemId: string, public readonly variantId?: string,
                public readonly uploadCategory?: EUploadCategory) {
        this.s3Key = s3Key;
        this.itemId = itemId;
        this.uploadCategory = uploadCategory;
        if (variantId) this.variantId = variantId;
    }
}

@Component({
    selector: 'rl-custom-workflow-files-upload-component',
    templateUrl: './custom-workflow-files-upload.component.html',
    styleUrls: ['./custom-workflow-files-upload.component.scss']
})
/**
 * @Deprecated
 */
export class CustomWorkflowFilesUploadComponent implements OnInit, OnDestroy {
    public EUploadStatus = EUploadStatus;
    public readonly VALIDATION_STATUS = UploadGroupModel.VALIDATION_STATUS;
    public readonly EUploadCategory = EUploadCategory;

    public allowedFileExtensions: string[] = [];

    public EPublicationDisplayProperties = EPublicationDisplayProperties;

    private uploadGroups: UploadGroupModel[] = [];
    public uploadGroups$: ReplaySubject<UploadGroupModel[]> = new ReplaySubject<UploadGroupModel[]>(1);
    public uploadsReady$: Observable<boolean> = this.uploadGroups$.pipe(
        switchMap((groups: UploadGroupModel[]) => {
            if (groups.length === 0 ||
                groups.some((group) => group.uploads.length !== group.requiredFileExtensions.length)) {
                return of(false);
            }

            const progressDone = groups
                .map((group) => group.uploads)
                .reduce((acc, uploads) => [...acc, ...uploads])
                .map((upload) => upload.progress$.pipe(map((progress) => progress === EUploadStatus.Done)));

            const itemsValid = groups.map((group) => group.validationStatus$.pipe(
                map((status) => status === this.VALIDATION_STATUS.VALID)));

            return combineLatest([...progressDone, ...itemsValid]).pipe(map((results) => results.every((result) => result)));
        }),
        startWith(false),
        shareReplay(1),
    );

    public publicationItemSubscription: Subscription;
    private publicationItems: PublicationItemModel[] = [];
    private publicationVariants: VariantModel[];
    private uploadButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(protected amazonService: AmazonService,
                protected publicationsService: PublicationsService,
                protected publicationItemsApiService: PublicationItemsApiService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IFilesUploadModalData) {
        this.publicationVariants = this.modalData.publicationVariants;
    }

    public ngOnInit(): void {
        this.allowedFileExtensions.push(
            ...[FileTypeUtil.EXTENSIONS.PNG, FileTypeUtil.EXTENSIONS.PDF, FileTypeUtil.EXTENSIONS.IDML],
            ...FileTypeUtil.FILE_TYPE_CATEGORIES.IMAGE.extensions);

        this.initModalButtons();
        this.getPublicationItems();

        this.uploadsReady$.pipe(takeUntil(this.onDestroySubject))
            .subscribe((ready) => this.uploadButton.disabled = !ready);
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public getPublicationItems(): void {
        this.publicationItemSubscription?.unsubscribe();

        this.publicationItemSubscription = this.publicationItemsApiService.getAllItemsForPublication(
            this.modalData.publication._id,
            this.modalData.step._id,
            undefined,
            undefined,
            undefined,
            (!this.modalData.publication.workflow.sequentialNumbering) ? ['campaignItems'] : undefined)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (items) => this.publicationItems = items,
                error: Toaster.handleApiError
            });
    }

    /**
     * Responds to file drops
     * @param {File[]} files
     */
    public onFilesChanged(files: File[]): void {
        if (files.length > 0) {
            const filteredFiles = files.filter(
                (file) => this.allowedFileExtensions.some(fileExtensionGroup =>
                    fileExtensionGroup.includes(FileTypeUtil.getExtensionFromFileName(file.name))));

            if (filteredFiles.length !== files.length) {
                Toaster.warn('One or more files did not have the correct filetype');
            }

            const newUploadGroups = this.groupAndStartUploads(filteredFiles);

            for (const newUploadGroup of newUploadGroups) {
                let uploadGroup = this.uploadGroups.find((foundGroup: UploadGroupModel) =>
                    foundGroup.identifier === newUploadGroup.identifier && foundGroup.variant?._id === newUploadGroup.variant?._id);

                if (!uploadGroup) { // uploadGroup is new
                    this.uploadGroups.push(newUploadGroup);
                    uploadGroup = newUploadGroup;
                } else { // uploadGroup exists, update with new uploads
                    const types = newUploadGroup.uploads.map((upload) => upload.fileType);
                    // Filter out new uploaded files
                    const filteredUploads = uploadGroup.uploads.filter((upload) => !types.includes(upload.fileType));

                    uploadGroup.uploads = [...filteredUploads, ...newUploadGroup.uploads];
                }

                // Maybe move to model itself
                uploadGroup.uploads = uploadGroup.uploads.filter(upload => uploadGroup.requiredFileExtensions.includes(upload.fileType));
            }
            this.uploadGroups$.next(this.uploadGroups);
        }
    }

    /**
     * Groups files together based on filename and starts the validation & uploads
     * @param {File[]} files
     * @returns {UploadGroupModel[]}
     */
    private groupAndStartUploads(files: File[]): UploadGroupModel[] {
        const uploadGroups = [];

        let invalidVariantsDetected = false;
        files.forEach((file) => {
            // Normalize the file name, because it could have another code page (selected files with the file system versus dnd)
            const uploadFile = file.name.normalize();
            const matchedPublicationItems = this.publicationItems.filter(
                (item) => {
                    const fileName = this.getPublicationItemFileName(item);
                    if (this.modalData.publication.workflow.sequentialNumbering) {
                        return uploadFile.includes(fileName);
                    } else {
                        if (this.publicationVariants?.length) {
                            const publicationItemVariant = this.publicationVariants.find(variant => {
                                const fileNameParts = [...variant.name.split(' '), fileName];
                                return FileTypeUtil.getFilenameWithoutExtension(uploadFile) === fileNameParts.join('_');
                            });

                            return !!publicationItemVariant;
                        } else {
                            return FileTypeUtil.getFilenameWithoutExtension(uploadFile) === fileName;
                        }
                    }
                });
            // TODO: Show dropdown for items that match multiple publication item ids
            if (matchedPublicationItems.length > 1) {
                ARLogger.warn(`Multiple publication items matched for file: ${file.name}`);
            }

            const matchedPublicationItem = matchedPublicationItems.length ? matchedPublicationItems[0] : null;
            const identifier = matchedPublicationItem ?
                matchedPublicationItem.publicationItemId : FileTypeUtil.getFilenameWithoutExtension(file.name);

            let fileVariant;
            if (matchedPublicationItem) {
                const pubItemFileName = this.getPublicationItemFileName(matchedPublicationItem);
                fileVariant = this.getUploadVariant(uploadFile, pubItemFileName);
                if ((!fileVariant && this.publicationVariants.length > 0) || (fileVariant && this.publicationVariants.length === 0)) {
                    invalidVariantsDetected = true;
                    return;
                }
            }

            let uploadGroup = uploadGroups.find((foundGroup) => foundGroup.identifier === identifier && foundGroup.variant?._id === fileVariant?._id);
            if (!uploadGroup) {
                uploadGroup = new UploadGroupModel(identifier, matchedPublicationItem, fileVariant);
                uploadGroups.push(uploadGroup);
            }

            uploadGroup.uploads.push(this.amazonService.createUpload(file));
        });

        if (invalidVariantsDetected) {
            Toaster.warn('One or more files did not belong to any variant of the publication');
        }
        return uploadGroups;
    }

    /**
     * Responds to delete button clicks
     * @param {UploadGroupModel} uploadGroup
     */
    public onDeleteButtonClicked(uploadGroup: UploadGroupModel): void {
        this.uploadGroups = this.uploadGroups.filter((foundGroup) => foundGroup !== uploadGroup);
        this.uploadGroups$.next(this.uploadGroups);
    }

    /**
     * Returns the upload for the provided fileType
     * @param {UploadModel[]} uploads
     * @param {string} fileType
     * @returns {UploadModel}
     */
    public getUploadForFileType(uploads: UploadModel[], fileType: string): UploadModel {
        return uploads.find((upload) => upload.fileType === fileType);
    }

    public onUploadButtonClicked(): void {
        const uploadAction = this.modalData.actions.find((action) =>
            action.name === ETransitionTriggerActionName.UPLOAD_ITEM_FILES);

        // Map the upload groups to a list of s3Keys, the itemId and variantId (optional)
        const groupedS3Keys$: Observable<KeyItemMapModel>[] =
            this.uploadGroups.map((group) =>
                group.uploads.map((upload) => upload.s3Key$.pipe(
                    withLatestFrom(group.publicationItem$),
                    map(([s3Key, publicationItem]) => new KeyItemMapModel(s3Key, publicationItem._id, group.variant?._id)),
                    take(1))))
                .reduce((acc, items) => [...acc, ...items]);

        // POST the array of s3Keys
        forkJoin(groupedS3Keys$).pipe(
            switchMap((groupedS3Keys) => {
                const itemIds = groupedS3Keys
                    .map((grouped) => grouped.itemId)
                    .filter((itemId, index, array) => array.indexOf(itemId) === index);

                return this.publicationsService.postTransitionItem(this.modalData.publication._id, uploadAction.transition, {
                    itemIds,
                    uploads: groupedS3Keys
                });
            }),
            takeUntil(this.onDestroySubject)
        ).subscribe({
            next: () => {
                Toaster.success('Items Uploaded, Transition Scheduled');
                this.fullModalService.close();
            },
            error: (error) => {
                Toaster.handleApiError(error);
                this.fullModalService.close();
            }
        });
    }

    private initModalButtons(): void {
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));
        this.uploadButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Upload', null, null, true);
        const uploadAction = new FullModalActionModel(this.uploadButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close());
        uploadAction.observable.subscribe(() => this.onUploadButtonClicked());

        this.fullModalService.setModalActions([cancelAction, uploadAction]);
    }
    /**
     * Get variant from the uploaded files
     */
    private getUploadVariant(uploadFileName, pubItemFileName): VariantModel {
        const fileName = FileTypeUtil.getFilenameWithoutExtension(uploadFileName);
        return this.publicationVariants.find((variant) => {
            const variantName = variant.name.replace(RLValidatorRegExConstants.PUBLICATION_ITEM_FILE_NAME, '').replace(/ /g, '_');
            const expectedPubItemFileName = `${variantName}_${pubItemFileName}`;

            return fileName.includes(expectedPubItemFileName);
        });
    }

    /**
     * Get publication file name
     */
    private getPublicationItemFileName(publicationItem: PublicationItemModel, variant?: VariantModel): string {
        const filePrefix = variant ? [...variant.name.split(' ')] : [];
        if (this.modalData.publication.workflow.sequentialNumbering) {
            filePrefix.push(publicationItem.numberOfPages === 1 ?
                `${publicationItem.firstPageNumber}` :
                `${publicationItem.firstPageNumber}-${publicationItem.firstPageNumber + publicationItem.numberOfPages - 1}`);
        }
        return filePrefix.length > 0 ? [...filePrefix, publicationItem.publicationItemId].join('_')
            .replace(RLValidatorRegExConstants.PUBLICATION_ITEM_FILE_NAME, '') : publicationItem.publicationItemId;
    }
}
