import {Component, inject} from '@angular/core';
import {DataFilterComponent} from '../../../../../../../../../components/data-filter/data-filter.component';
import {PublicationItemSelectionService} from '../../../../custom-workflow-item-selection/publication-item-selection.service';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {WorkflowLayoutItem} from '../../../../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'sticky-list-data-filter',
    templateUrl: '../../../../../../../../../components/data-filter/data-filter.component.html',
    styleUrls: ['../../../../../../../../../components/data-filter/data-filter.component.scss']
})
export class StickyListDataFilterComponent extends DataFilterComponent {
    private publicationItemSelectionService = inject(PublicationItemSelectionService);

    private publicationItemSubscription: Subscription;

    private get publicationItemControl(): FormControl {
        return this.filtersForm.get('publicationItem') as FormControl;
    }
    public onApply(): void {
        if (this.publicationItemControl.value) {
            this.filterOnPublicationItem();
        } else {
            this.cancelFilterOnPublicationItem();
        }
    }

    private filterOnPublicationItem(): void {
        if (this.publicationItemSubscription && this.publicationItemSubscription.closed === false) {
            // Subscription already created and not closed
            this.setFilterOnPublicationItem(this.publicationItemSelectionService.getSelectedWorkflowLayoutItem());
            return;
        }
        this.publicationItemSubscription = this.publicationItemSelectionService.selectedWorkflowLayoutItem$
            .pipe(
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((selectedWorkflowLayoutItem) => this.setFilterOnPublicationItem(selectedWorkflowLayoutItem));
    }

    private setFilterOnPublicationItem(selectedItem?: WorkflowLayoutItem): void {
        const publicationItemIds = selectedItem?.publicationItems.map(item => item._id) || [];
        // Can set value to null, when no item selected, the subscription is still alive
        this.publicationItemControl.setValue(publicationItemIds.length > 0 ? publicationItemIds : null);
        super.onApply();
    }

    private cancelFilterOnPublicationItem(): void {
        this.publicationItemSubscription?.unsubscribe();
        this.setFilterOnPublicationItem();
    }
}
