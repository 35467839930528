import {autoserialize, autoserializeAs} from 'cerialize';
import {WorkflowConfigurationModel} from './workflow-configuration.model';
import {FileModel} from './file.model';
import {AssetFileTypePipe} from '../../pipes/asset-file-type.pipe';
import {ICardMediaType} from '@relayter/rubber-duck';
import {EAssetType} from './asset.model';

export class PublicationModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserializeAs(WorkflowConfigurationModel) public workflow: WorkflowConfigurationModel;
    @autoserialize public variants: string[];
    @autoserializeAs(Date) public deadline: Date;
    @autoserializeAs(FileModel) public thumbnail?: FileModel;

    public getThumbnail(): ICardMediaType {
        if (!this.thumbnail?.url || !this.thumbnail?.extension) {
            return {
                url: 'assets/images/empty_states/default-small.svg',
                type: EAssetType.IMAGE
            };
        }

        return {
            url: this.thumbnail.url,
            type: AssetFileTypePipe.format(this.thumbnail.extension)
        };
    }
}

export class PublicationPostModel {
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserialize public workflow: string;
    @autoserialize public variants: string[];
    @autoserializeAs(Date) public deadline: Date;

    constructor(name: string, workflow: string, tags: string[], variants: string[], deadline: Date) {
        this.name = name;
        this.workflow = workflow;
        this.tags = tags;
        this.variants = variants;
        this.deadline = deadline ? deadline : null;  // Null to reset the date
    }
}

export class PublicationPatchModel {
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @autoserializeAs(Date) public deadline: Date;

    constructor(name: string, tags: string[], deadline: Date) {
        this.name = name;
        this.tags = tags;
        this.deadline = deadline ? deadline : null; // Null to reset the date
    }
}
