<div class="container">
    <nuc-button-bar class="left-bar">
        <nuc-input *ngIf="searchPlaceholder"
                   [(data)]="searchValue"
                   prefix="nucicon_search"
                   (debounceValueChanged)="onSearchBarValueUpdated($event)"
                   [placeholder]="searchPlaceholder">
        </nuc-input>
        <ng-container *ngIf="advancedFilter">
            <div class="vertical-line"></div>
            <data-filter
                [dataFilterContext]="dataFilterContext"
                [filters]="dataFilters">
            </data-filter>
        </ng-container>
        <nuc-overlay-button *ngIf="propertySettingsContext"
                            icon="nucicon_eye"
                            [showIndicator]="false"
                            nucTooltip="View settings"
                            [content]="propertySettingsComponent">
        </nuc-overlay-button>
    </nuc-button-bar>


    <nuc-button-bar>
        <form [formGroup]="formGroup">
            <nuc-dropdown *ngIf="sort"
                          formControlName="sort"
                          placeholder="Select sort by"
                          [nullOption]="false"
                          [items]="sortingOptions">
            </nuc-dropdown>
        </form>

        <nuc-button-secondary
            *ngIf="sort"
            [icon]="getSortingDirectionIcon(!sortDescending)"
            (click)="onSortingDirectionClicked()"
            [loading]="isLoading"
            [disabled]="disableSort">
        </nuc-button-secondary>

        <paginator [viewId]="viewId"
                   [loading]="isLoading"
                   [disableNextPage]="disableNextPage"
                   [disablePageSizeOptions]="disablePageOptions">
        </paginator>
    </nuc-button-bar>
</div>

<ng-template #propertySettingsComponent>
    <rl-property-settings-component [context]="propertySettingsContext"></rl-property-settings-component>
</ng-template>
