import {autoserialize, autoserializeAs} from 'cerialize';
import {CampaignItemModel} from './campaign-item.model';
import {ITableItem} from '../interfaces/table-item.interface';
import {PublicationItemModel} from './publication-item.model';
import {PositionModel} from './position.model';
import {VariantModel} from './variant.model';
import {EStickyNoteStatus} from '../../app.enums';
import {UserModel} from './user.model';

export class NoteTimeRange {
    @autoserialize public start: number;
    @autoserialize public end: number;

    constructor(start: number, end?: number) {
        this.start = start;
        this.end = end;
    }
}

export class StickyNoteModel implements ITableItem {
    @autoserializeAs(PositionModel) public position: PositionModel;
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserialize public _id: string;
    @autoserialize public message: string;
    @autoserialize public createdAt: string;
    @autoserialize public status: EStickyNoteStatus;
    @autoserializeAs(CampaignItemModel) public campaignItem: CampaignItemModel;
    @autoserializeAs(PublicationItemModel) public publicationItem: PublicationItemModel;
    @autoserialize public totalComments: number;
    @autoserializeAs(VariantModel) public variant: VariantModel;
    @autoserializeAs(NoteTimeRange)public timeRange: NoteTimeRange;

    constructor(position?: PositionModel, message?: string, campaignItem?: CampaignItemModel, status?: EStickyNoteStatus,
                publicationItem?: PublicationItemModel, variant?: VariantModel) {
        this.position = position;
        this.message = message;
        this.campaignItem = campaignItem;
        this.status = status;
        this.publicationItem = publicationItem;
        this.variant = variant;
    }
}

export class StickyNotePostModel {
    @autoserialize public message: string;
    @autoserializeAs(PositionModel) public position: PositionModel;
    @autoserialize public campaignItem: string;
    @autoserializeAs(NoteTimeRange) public timeRange: NoteTimeRange;
    @autoserialize public variant: string;

    constructor(message: string, position: PositionModel, campaignItem?: CampaignItemModel, variant?: string, timeRange?: NoteTimeRange) {
        this.message = message;
        this.position = position;
        if (campaignItem) {
            this.campaignItem = campaignItem._id;
        }
        if (variant) {
            this.variant = variant;
        }
        if (timeRange) {
            this.timeRange = timeRange;
        }
    }
}
