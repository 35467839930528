import {autoserialize, autoserializeAs} from 'cerialize';
import {CustomWorkflowComponentModel} from './custom-workflow-component.model';
import {ITableItem} from '@relayter/rubber-duck';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class NotificationReceiverModel {
    @autoserialize public type: string;
    @autoserialize public roles: string[];
    @autoserialize public emails: string[];
    @autoserialize public property: string;
}

export class NotificationPropertyModel {
    @autoserialize public label: string;
    @autoserialize public property: string;
}

export class ScheduleNotificationModel {
    @autoserialize public hours: number;
    @autoserialize public alertType: string;
    @autoserialize public message: string;
    @autoserializeAs(NotificationReceiverModel) public receivers: NotificationReceiverModel[];
    @autoserializeAs(NotificationPropertyModel) public properties: NotificationPropertyModel[];
    @autoserialize public includeLinks: boolean;
}

export class StepScheduleModel {
    @autoserialize public hours: number;
    @autoserializeAs(ScheduleNotificationModel) public notifications: ScheduleNotificationModel[];
}

export class CustomWorkflowStepModel implements ITableItem, IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(CustomWorkflowComponentModel) public components: CustomWorkflowComponentModel[];
    @autoserialize public icon: string = 'nucicon_gear'; // default icon
    @autoserialize public permissions: string[] = [];
    @autoserializeAs(StepScheduleModel) schedule: StepScheduleModel;

    public getTitle(): string {
        return this.name;
    }
    public getValue(): string {
        return this._id;
    }
}
