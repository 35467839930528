import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EJobStatus, JobModel} from '../../../models/api/job.model';
import {filter, takeWhile} from 'rxjs/operators';
import {MonitoredService} from './monitored.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoredJobsService extends MonitoredService<JobModel> {
    /**
     * Creates a new Observable to monitor a job
     * @param {string} jobId
     * @returns {Observable<JobModel>}
     */
    public getItemMonitor(jobId: string): Observable<JobModel> {
        return this.socketService.jobMessages$
            .pipe(
                filter(job => job._id === jobId),
                takeWhile(job => ![EJobStatus.DONE, EJobStatus.FAILED].includes(job.status), true)
            );
    }

}
