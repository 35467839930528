import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ARBaseComponent} from '@relayter/core';

@Component({
    selector: 'rl-reactive-file-input',
    styleUrls: ['reactive-file-input.component.scss'],
    templateUrl: 'reactive-file-input.component.html'
})
export class ReactiveFileInputComponent extends ARBaseComponent {

    private static readonly TITLES = {
        DRAG_TITLE: 'Now drop it',
        NO_DRAG_TITLE: 'Drag your files here'
    };

    private static readonly IMAGE_PATHS = {
        DRAG_ACTIVE: '/assets/images/icon_drag_drop_active.svg',
        NO_DRAG_ACTIVE: '/assets/images/icon_drag_drop.svg'
    };

    @ViewChild('fileInput') public fileInput: ElementRef;

    @Input() public smallInputBox: boolean = false;
    @Input('inactive-title') public inActiveTitle: string = ReactiveFileInputComponent.TITLES.NO_DRAG_TITLE;
    @Input() public multiple: boolean = true;
    @Input() public fillSpace: boolean = false;
    @Output() public filesChanged = new EventEmitter();

    @Input() public isDragging: boolean = false;
    public fileValid = false;

    /**
     * Get the isDragging & drop title based on if the component is currently in isDragging mode
     * @param drag
     * @returns {string|string}
     */
    public getDragDropTitle(drag: boolean): string {
        return drag ? ReactiveFileInputComponent.TITLES.DRAG_TITLE : this.inActiveTitle;
    }

    /**
     * Get the isDragging & drop asset based on if the component is currently in isDragging mode
     * @param drag
     * @returns {string|string}
     */
    public getDragDropImage(drag: boolean): string {
        return drag ? ReactiveFileInputComponent.IMAGE_PATHS.DRAG_ACTIVE : ReactiveFileInputComponent.IMAGE_PATHS.NO_DRAG_ACTIVE;
    }

    /**
     * On isDragging entered event responder, sets isDragging to true
     */
    public onDragEntered(): void {
        this.isDragging = true;
    }

    /**
     * On isDragging exited event responder, sets isDragging to false
     */
    public onDragExited(): void {
        this.isDragging = false;
    }

    /**
     * Trigger native file explorer
     */
    public trigger(): void {
        this.fileInput.nativeElement.click();
    }

    /**
     * On files changed, disable isDragging mode and filter out the unsupported file types and emit any valid changed files.
     * also show an informative message if one or more assets are not supported.
     * @param event
     */
    public onFilesChanged(event): void {
        this.isDragging = false;
        let files: File[] = [];
        if (event.target.files && event.target.files.length > 0) {
            files = Array.from(event.target.files);
        }

        this.fileValid = files.length > 0;
        event.target.value = '';
        this.filesChanged.emit(files);
    }
}
