import {DestroyRef, inject, Injectable} from '@angular/core';
import {SocketService} from '../socket/socket.service';
import {Observable, ReplaySubject} from 'rxjs';
import {IMonitoredItem} from './monitored-item.interface';
import {UserService} from '../users.service';

@Injectable({
    providedIn: 'root'
})
export abstract class MonitoredService<T extends IMonitoredItem> {
    protected socketService = inject(SocketService);
    protected userService = inject(UserService);
    protected destroyRef = inject(DestroyRef);

    protected monitoredItems: T[] = [];
    public monitoredItemsSubject = new ReplaySubject<T[]>(1);

    public abstract getItemMonitor(itemId): Observable<T>;

    constructor() {
    }

    /**
     * Returns a list of all currently active items
     * @returns {Observable<T[]>}
     */
    public getAllMonitoredItems(): Observable<T[]> {
        return this.monitoredItemsSubject.asObservable();
    }

    /**
     * announce monitored transitions
     */
    protected announceMonitoredItems(): void {
        this.monitoredItemsSubject.next(Array.from(this.monitoredItems));
    }

    /**
     * add item to monitor
     * @param {T} item
     */
    public addItemToMonitor(item: T): void {
        this.monitoredItems.unshift(item);
        this.announceMonitoredItems();
    }

    /**
     * Clears an item from the monitoredItems array
     * @param {string} itemId
     */
    public removeMonitoredItem(itemId: string): void {
        this.monitoredItems = this.monitoredItems.filter((monitoredItem) => monitoredItem._id !== itemId);
        this.announceMonitoredItems();
    }

    /**
     * Remove all monitored items
     */
    public removeAllMonitoredItems(): void {
        this.monitoredItems = [];
        this.announceMonitoredItems();
    }
}
