import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map, takeWhile} from 'rxjs/operators';
import {ETransitionStatus, TransitionItemModel} from '../../../models/api/transition-item.model';
import {Deserialize} from 'cerialize';
import {MonitoredService} from './monitored.service';

@Injectable({
    providedIn: 'root'
})
export class MonitoredTransitionsService extends MonitoredService<TransitionItemModel> {
    private workflowStepItemsChangedSubject = new Subject<TransitionItemModel>();
    public workflowStepItemsChangedSubject$ = this.workflowStepItemsChangedSubject.asObservable();

    /**
     * Creates a new Observable to monitor a transition
     * @param {string} transitionItemId
     * @returns {Observable<TransitionItemModel>}
     */
    public getItemMonitor(transitionItemId: string): Observable<TransitionItemModel> {
        return this.socketService.publicationUpdates$
            .pipe(
                map(messageBody => Deserialize(messageBody.data, TransitionItemModel)),
                filter((transitionItem: TransitionItemModel) => transitionItem._id === transitionItemId),
                takeWhile((transitionItem: TransitionItemModel) =>
                    ![ETransitionStatus.DONE, ETransitionStatus.FAILED].includes(transitionItem.status), true)
            );
    }

    /**
     * Add/updates transition item to monitor
     * @param {TransitionItemModel} transitionItem
     */
    public updateTransitionMonitor(transitionItem: TransitionItemModel): void {
        const updateTransitionItem = this.monitoredItems.find(item => item._id === transitionItem._id);
        if (updateTransitionItem) {
            updateTransitionItem.status = transitionItem.status;
            updateTransitionItem.error = transitionItem.error;
            updateTransitionItem.progress = transitionItem.progress;
        } else {
            this.addItemToMonitor(transitionItem);
        }
        this.announceMonitoredItems();
    }

    public workflowStepItemsChanged(transitionItem: TransitionItemModel): void {
        this.workflowStepItemsChangedSubject.next(transitionItem);
    }
}
