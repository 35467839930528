import {autoserialize, autoserializeAs} from 'cerialize';
import {AssetModel} from './asset.model';
import {ISpreadContentTypeBody} from '../interfaces/spread-content-type-body.interface';
import {ProductModel} from './product.model';
import {IDataFieldsModel} from '../interfaces/datafields-model.interface';
import {PositionModel} from './position.model';
import {IFileType} from '../../classes/file-type.util';
import {AssetsUtil} from '../../classes/assets.util';
import {ChangeModel} from './changelog.model';
import {FileModel} from './file.model';
import {PublicationItemModel} from './publication-item.model';
import {ICustomWorkflowActionItem} from '../interfaces/custom-workflow-action-item.interface';

export class CampaignItemModel implements ICustomWorkflowActionItem, ISpreadContentTypeBody, IDataFieldsModel {
    @autoserialize public _id: string;
    @autoserialize public briefingItemId: string;
    @autoserializeAs(ProductModel) public products: ProductModel[] = [];
    @autoserializeAs(AssetModel) public assets: AssetModel[] = [];
    @autoserialize public tags: string[];
    @autoserializeAs(Object) public dataFields: Record<string, any>;
    @autoserialize public updatedAt: string;
    @autoserialize public createdAt: string;

    @autoserialize public itemsGenerated: number;
    @autoserializeAs(FileModel) public thumbnail: FileModel;

    @autoserialize public assignedPublicationItems: PublicationItemModel[];
    @autoserializeAs(ChangeModel) public changes: ChangeModel[];
    @autoserializeAs(PositionModel) public position: PositionModel;

    // TODO: [DATAFIELDS] find better solution for this thumb property (dont forget to update the defaultColumns in campaign-briefing component)
    // TODO: Update this to only use thumbnail when we align all the get campaignItems api calls
    public get thumb(): string {
        return this.thumbnail
            ? this.thumbnail.url
            : this.assets?.length && this.assets[0].getThumbnailMedia().url
                ? this.assets[0].getThumbnailMedia().url
                : null;
    }

    // interface ISpreadContentTypeBody functions are as follows:
    /**
     * Interface / Convenience method to get an array of loadable image url for PIXI.loader
     * @param {IFileType} [category]
     * @returns string[]
     */
    public getUniqueAssetUrls(category?: IFileType): string[] {
        const images = category ? AssetsUtil.filterAssetsByCategory(this.assets, category) : this.assets;

        return [...new Set([...images.map((image) => image.getThumbnailMedia().url)])];
    }

    /**
     * Interface method to check if this Content Type Body has loadable content (meaning it has images that need to be loaded by PIXI.loader)
     */
    public isLoadable(): boolean {
        return true;
    }

    public getSpreadEditorTitle(): string {
        return this.briefingItemId;
    }
}

export class CampaignItemBodyModel {
    @autoserialize public products: string[] = [];
    @autoserialize public assets: string[] = [];
    @autoserialize public tags: string[];
    @autoserializeAs(Object) public dataFields: Record<string, any>;

    constructor(products: string[], assets: string[], dataFields?: Record<string, any>, tags?: string[]) {
        this.products = products;
        this.assets = assets;
        this.dataFields = dataFields;
        this.tags = tags;
    }
}
