@if (isInline) {
    <div class="header">
        <div class="header-grid">
            <div class="left"></div>
            <div class="center">
                <h1 class="title">Add files to publication item</h1>
                <p class="subtitle">Upload the Export file, Source file and any optional files linked to the source file.</p>
            </div>
            <div class="right">
            </div>
        </div>
    </div>
}

@if (publicationItem()) {
    <div class="content" [class.inline]="isInline">
        <div class="upload-container">
            <div class="upload-files">
                @for (fileConfig of updateItemFileTypesConfig(); track fileConfig) {
                    <upload-item-file-input-component
                        [saveClicked]="saveClicked"
                        [fileUploadTitle]="fileConfig.title"
                        [dragTitle]="fileConfig.dragTitle"
                        [subtitle]="fileConfig.subtitle"
                        [active]="fileConfig.active"
                        [newFileUploaded]="fileConfig.newFileUploaded"
                        [required]="fileConfig.required"
                        [fileTypeCategories]="fileConfig.requiredFileTypes"
                        (filesChanged)="onFilesChanged($event, fileConfig.uploadCategory)">
                    </upload-item-file-input-component>
                }
            </div>
            <hr>
            @if (exportFile() || sourceFile() || files().length > 0) {
                <div class="upload-details">
                    @if ("exportFile() || sourceFile()") {
                        <div class="upload-row preview-source-uploads">
                            <div *ngIf="exportFile()">
                                <nuc-label>Uploaded Export file</nuc-label>
                                <file-upload-card-view class="item"
                                                       [itemWidth]="200"
                                                       [uploadFile]="exportFile().uploads[0]"
                                                       (deleteAssetClicked)="onDeleteUploadItemClicked(exportFile())">
                                </file-upload-card-view>
                            </div>

                            <div *ngIf="sourceFile()">
                                <nuc-label>Uploaded Source file</nuc-label>
                                <file-upload-card-view class="item"
                                                       [itemWidth]="200"
                                                       [uploadFile]="sourceFile().uploads[0]"
                                                       (deleteAssetClicked)="onDeleteUploadItemClicked(sourceFile())">
                                </file-upload-card-view>
                            </div>
                        </div>
                    }

                    @if (files().length) {
                        <div class="upload-row">
                            <nuc-label>Uploaded optional Files</nuc-label>
                            <div class="upload-items">
                                <file-upload-card-view *ngFor="let file of files()"
                                                       class="item"
                                                       [itemWidth]="200"
                                                       [uploadFile]="file.uploads[0]"
                                                       (deleteAssetClicked)="onDeleteUploadItemClicked(file)">
                                </file-upload-card-view>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        <div class="sidebar">
            <custom-workflow-publication-item-details-component
                *ngIf="publicationItem"
                [dataFields]="dataFields"
                [isForm]="true"
                [selectedPublicationItemId]="publicationItem()._id"
                [publicationItems]="[publicationItem()]">
            </custom-workflow-publication-item-details-component>
        </div>
    </div>
} @else {
    <rl-loading-indicator></rl-loading-indicator>
}

<div *ngIf="isInline" class="footer">
    <div class="buttons">
        <ng-container *ngFor="let action of actions" [ngSwitch]="action.button.buttonType">
            <nuc-button-primary *ngSwitchCase="buttonTypes.PRIMARY"
                                [loading]="action.button.loading"
                                [icon]="action.button.icon"
                                [text]="action.button.text"
                                [disabled]="action.button.disabled"
                                [nucTooltip]="action.button.tooltip?.text"
                                [nucTooltipPosition]="action.button.tooltip?.position"
                                (click)="buttonClicked(action.observable)">
            </nuc-button-primary>
            <nuc-button-secondary *ngSwitchCase="buttonTypes.SECONDARY"
                                  [loading]="action.button.loading"
                                  [icon]="action.button.icon"
                                  [text]="action.button.text"
                                  [disabled]="action.button.disabled"
                                  [nucTooltip]="action.button.tooltip?.text"
                                  [nucTooltipPosition]="action.button.tooltip?.position"
                                  (click)="buttonClicked(action.observable)"></nuc-button-secondary>
            <nuc-button-destructive *ngSwitchCase="buttonTypes.DESTRUCTIVE"
                                    [loading]="action.button.loading"
                                    [icon]="action.button.icon"
                                    [text]="action.button.text"
                                    [disabled]="action.button.disabled"
                                    [nucTooltip]="action.button.tooltip?.text"
                                    [nucTooltipPosition]="action.button.tooltip?.position"
                                    (click)="buttonClicked(action.observable)"></nuc-button-destructive>
        </ng-container>
    </div>
</div>
